import Vue from 'vue'
import VModal from 'vue-js-modal'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'


Vue.use(VModal)
Vue.use(VueTelInput)
Vue.use(Vuelidate)
Vue.use(Toasted, {
  duration: 5000
})

