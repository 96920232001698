var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'vendor': _vm.$route.fullPath.includes('/vendor') },style:({
          '--primary': _vm.theme.primary,
          '--secondary': _vm.theme.secondary,
          '--third': _vm.theme.third,
          '--lightBackground': _vm.theme.lightBackground,
          '--darkBackground': _vm.theme.darkBackground,
          '--lightTextColor': _vm.theme.lightTextColor,
          '--darkTextColor': _vm.theme.darkTextColor,
        })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }