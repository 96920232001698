export default {
  // footer
  termOfService: 'Termo de Serviços',
  privacyPolicy: 'Política de Privacidade',
  xCommunications: 'xComunicações',
  xCommunicationsInvestorRelations: 'Relações com Investidores',
  xCommunicationsMediaRequest: 'Solicitação de mídia',
  xCommunicationsOurPositionPrivacy: 'Nossa posição sobre privacidade',
  xCommunicationsKnowledgeBase: 'Base de Conhecimento',
  xCommunicationsOurPosition: 'Nossa posição sobre cyberbullying',
  premiumTools: 'Ferramentas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'plugin sayHola Live Chat',
  premiumToolsSayHolaBlogPlugin: 'plugin do sayHola Blog',
  premiumToolsTogetherSayHola: 'juntos por sayHola',
  premiumToolsLiveLeagueApp: 'App Live League',
  programs: 'Programas',
  programsAdvertiseHola: 'Anuncie com Hola',
  programsFreelancerProgram: 'Programa Freelancer',
  programsAffiliateRewardsProgram: 'Programa de recompensas para afiliados',
  terms: 'Termos',
  termsAdsPolicy: 'Política de Anúncios',
  termsPrivacyPolicy: 'Política de Privacidade',
  termsCommunityStandards: 'Padrões da Comunidade',
  termsTermsUse: 'Termos de uso',
  termsLoyaltyRewardsTerms: 'Termos de recompensas de fidelidade',
  // Home Page
  homeSlider: {
    haveAccount: 'já tem uma conta?',
    changeNumber: 'Precisa mudar seu número?',
    getStartedButton: 'Comece agora!',
    sliderPrevButton: 'Voltar',
    sliderNextButton: 'Continue a ler',
    slogan1: 'A plataforma de tecnologia Hola Cross Platform Chatbot Bridge ajuda a converter seus usuários em clientes de longo prazo. Com o Chatbot Bridge, você pode gerar clientes a partir de qualquer aplicativo de bate-papo (WhatsApp, Telegram etc) e gerenciá-los em um único painel.',
    slogan2: 'Nossas soluções tecnológicas incluem \'modelos fáceis de construir\' para todos os tipos de serviço, de \'pedidos de comida\' a \'suporte ao cliente\' usando apenas recursos que você seleciona e usa.',
    slogan3: 'Você pode criar seus modelos usando Chatbots baseados em botões para mapear as conversas com os clientes. Ao antecipar os tipos de perguntas que os clientes provavelmente farão, o chatbot é programado para responder adequadamente, tornando a experiência do cliente amigável e autêntica.',
    slogan4: 'Veja nossa demonstração ou comece hoje fazendo login e tornando-se um cliente Premium',
    needMoreInfo: 'Precisa de mais informações?',
    learnMore: 'Saber mais'
  },
  signIn: 'Entrar',
  recovery: 'Recuperação',
  otp_unconfirmed: 'Otp não confirmado, tente novamente',
  otpDescription: 'Um código sms foi enviado para você. Por favor, insira o código abaixo.',
  otpEnterYourOtp: 'Digite seu código otp',
  otpEnterYourPin: 'Digite seu código PIN',
  verify_otp: 'confirme',
  otpDontReceiveCode: 'Não recebeu o código?',
  otpRequestSMSAgain: 'Solicite SMS novamente',
  otpRequestNewCode: 'Solicite um novo código em',
  copyright: 'xComunicações. Todos os direitos reservados',
  enterPhoneNumber: 'Escolha seu país e digite seu número de telefone completo.',
  login: 'Conecte-se',
  validationSelectFile: 'Selecione e faça upload da foto',
  validationSameAs: 'As senhas não coincidem',
  validationMaxLengthField: 'Número máximo de caracteres de campo - :count',
  validationMinLengthField: 'Número mínimo de caracteres de campo - :count',
  validationRequired: 'Preencha este campo',
  validationEmail: 'Entre com um email válido',
  validationUrl: 'Insira um url válido',
  needChangePhone: 'Precisa mudar seu número?',
  needMoreInfo: 'Precisa de mais informações?',
  learnMore: 'Saber mais',
  startUsingHola: 'Comece a usar sayHola para visualizar o <br> Espaço Social',
  startUsingHolaFollow: 'Comece a usar sayHola para seguir',
  socialSpace: 'espaço social',
  socialSpaceInvite: 'Convite para espaço social',
  socialSpaceInviteMessage: 'um espaço privado e seguro para empresas, celebridades, blogueiros, influenciadores, comunidade e indivíduos, sem comentários e notícias falsas',
  enterPhone: 'Digite o telefone',
  payNow: 'Pague agora',
  formCardName: 'Nome do Titular',
  formCardNumber: 'Número do cartão',
  formExpDate: 'Data de validade',
  formCvv: 'CVV',
  errorEmptyField: 'Este campo é obrigatório',
  paymentBtnPay: 'Pague agora',
  errorTitle: 'Erro',
  error: 'Desculpe, algo deu errado',
  errorEmailInvalid: 'Digite um endereço de e-mail válido',
  errorPhoneInvalid: 'Número de telefone inválido',
  errorStripe: 'Certifique-se de preencher todos os campos corretamente',
  offer: 'Oferecer',
  enterCode: 'Coloque o código',
  enterPin: 'Entrar no pino',
  enterCardholderName: 'Insira o nome do titular do cartão',
  logout: 'Sair',
  confirmation: 'Confirmação',
  thanksJoining: 'Obrigado por se juntar',
  successText: 'Visite o FAQ e aprenda como usar os muitos recursos especiais. Contate o suporte para perguntas adicionais. O que você gostaria de fazer?',
  downLoadApp: 'Baixar aplicativo',
  returnToHola: 'Voltar para Hola',
  addSponsor: 'Adicionar patrocinador',
  videoAndFAQ: 'Vídeos e perguntas frequentes',
  pin_unconfirmed: 'Insira o PIN correto!',
  selectPackage: 'Selecione o pacote',
  offerExpired: 'A oferta especial expirou!',
  jobBrief: 'Resumo do trabalho',
  responsibilities: 'Responsabilidades',
  requirements: 'Requisitos',
  currentJobPositions: 'Oportunidades de trabalho atuais',
  sendCampaign: 'Enviar campanha',
  footerPrivateAppJobsPage: 'Oportunidades de Trabalho',
  enterLatinLetter: 'Este campo só pode conter caracteres alfanuméricos, traço e sublinhado [a-z0-9-_]',
  nicknameExist: 'Apelido já existe!',
  successCreateProfile: 'Perfil criado com sucesso!',
  downloadApp: 'baixe o aplicativo',
  faqAndVideo: 'faq e video',
  congratulations: 'Parabéns',
  hosSubmitMessage: 'Como enviar sua inscrição:',
  profilePicture: 'Foto do perfil',
  profilePictureMessage: 'Adicione sua foto de perfil do Social Space, queremos saber com quem estamos conversando.',
  chatProfilePictureMessage: 'Adicione sua foto de perfil, queremos saber com quem estamos conversando.',
  firstName: 'Primeiro nome',
  lastName: 'Último nome',
  nickname: 'Apelido',
  location: 'Localização (cidade, país)',
  btnNext: 'Próximo',
  btnFinish: 'Terminar',
  email: 'O email',
  emailMessage: 'Seu e-mail será usado apenas para recuperação. Ao inserir o seu e-mail, você aceita a política de privacidade e os termos e condições da sayHola. Os links serão enviados para o seu e-mail para recuperação.',
  changesSaved: 'Alterações salvas!',
  emptyProfilePictureField: 'Por favor carregue sua foto de perfil',
  register: 'Registro',
  resendOtpMessage: 'Mais um SMS foi enviado.',
  loginByQRCodeMessage: 'Código QR para login via smartphone',
  authOption: 'Selecione uma opção para Login.',
  newRegistration: 'novo registro',
  registrationOption: 'O registro requer um e-mail e nome de usuário para usar os serviços. Uma vez logado, você tem a opção de sincronizar com outros aplicativos de mensagens usando seu número de telefone',
  loginWithApp: 'login com aplicativo',
  loginWithAppOption: 'Abra o Hola no seu telefone, acesse o menu localizado no canto superior direito do aplicativo. Clique no ícone do código QR. Aponte seu telefone para esta tela para confirmar o login.',
  recoveryOption: 'recuperação',
  recoveryOptionText: 'Para Recuperação Digite seu e-mail cadastrado para fazer login usando o Hot-link. Saiba mais visitando a seção de perguntas frequentes',
  enterEmailRecovery: 'Digite seu e-mail cadastrado para recuperação:',
  startUsingSayHola: 'Comece a usar o sayHola',
  formEmail: 'Seu email',
  notFoundUserLabel: 'Usuário com este e-mail não encontrado',
  successSendRecoveryEmailMessage: 'Verifique seu e-mail e siga as instruções!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programa de Agência BDTL',
  agencyTerms: 'Termos da agência',
  holaExtensions: 'Extensão Hola',
  holaBlog: 'Hola Blog',
  authSlogan1: 'Estamos agora a recrutar Motoristas de Entrega para Alimentos e Produtos na América Latina',
  authSlogan2: 'Pagamos as taxas de viagem mais altas: $ 2 por viagem mais extra por KM',
  authSlogan3: 'Despaxo não recebe comissões dos motoristas',
  authSlogan4: 'Seja seu próprio chefe - receba o pagamento super rápido',
  authSlogan5: 'Registre-se hoje!',
  loginByEmail: 'Entrar por e-mail',
  registerByEmail: 'Registre-se por e-mail',
  registerToday: 'Registre-se hoje.'
}
