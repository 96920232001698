export default {
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Relaciones con inversionistas',
  xCommunicationsMediaRequest: 'Solicitud de Medios',
  xCommunicationsOurPositionPrivacy: 'Nuestra posición en Privacidad',
  xCommunicationsKnowledgeBase: 'Base de conocimientos',
  xCommunicationsOurPosition: 'Nuestra Posición en Ciberacoso',
  premiumTools: 'Herramientas Premium',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together por sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programas',
  programsAdvertiseHola: 'Anunciece con Hola',
  programsFreelancerProgram: 'Programa Freelancer',
  programsAffiliateRewardsProgram: 'Programa de recompensas para afiliados',
  terms: 'Términos',
  termsAdsPolicy: 'Política de Anuncios',
  termsPrivacyPolicy: 'Política de Privacidad',
  termsCommunityStandards: 'Estándares de la Comunidad',
  termsTermsUse: 'Términos de Uso',
  termsLoyaltyRewardsTerms: 'Términos de las recompensas por fidelidad',
  termOfService: 'Términos de Servicios',
  privacyPolicy: 'Política de Privacidad',
  // home slider
  homeSlider: {
    haveAccount: 'Ya tienes una cuenta?',
    changeNumber: 'Necesitas cambiar tu número?',
    getStartedButton: 'Empieza ahora!',
    sliderPrevButton: 'Atras',
    sliderNextButton: 'Continuar leyendo',
    slogan1: 'La plataforma de tecnología Hola Cross Platform Chatbot Bridge ayuda a convertir a sus usuarios en clientes a largo plazo. Con Chatbot Bridge, puede generar clientes desde cualquier aplicación de chat (WhatsApp, Telegram, etc.) y administrarlos todos en un solo tablero.',
    slogan2: 'Nuestras soluciones tecnológicas incluyen "plantillas fáciles de crear" para todo tipo de servicios, desde "pedidos de comida" hasta "atención al cliente" utilizando solo las funciones que usted seleccione y use.',
    slogan3: 'Puede crear sus plantillas utilizando chatbots basados en botones para trazar conversaciones con los clientes. Al anticipar el tipo de preguntas que es probable que hagan los clientes, el chatbot está programado para responder adecuadamente, lo que hace que la experiencia del cliente sea fácil de usar y auténtica.',
    slogan4: 'Vea nuestra demostración o comience hoy iniciando sesión y convirtiéndose en un cliente Premium',
    needMoreInfo: '¿Necesitas más información?',
    learnMore: 'Aprende más'
  },
  signIn: 'Ingresar',
  recovery: 'Recuperar',
  otp_unconfirmed: 'Otp no confirmado, vuelve a intentarlo',
  otpDescription: 'Se le ha enviado un código sms. Ingrese el código a continuación.',
  otpEnterYourOtp: 'Ingrese su código otp',
  otpEnterYourPin: 'Ingrese su código pin',
  verify_otp: 'Confirmar',
  otpDontReceiveCode: '¿No recibiste el código?',
  otpRequestSMSAgain: 'Solicitar SMS de nuevo',
  otpRequestNewCode: 'Solicite un nuevo código en',
  copyright: 'xComunicaciones. Reservados todos los derechos',
  login: 'Acceso',
  validationSelectFile: 'Seleccionar y subir foto',
  validationSameAs: 'Las contraseñas no coinciden',
  validationMaxLengthField: 'Número máximo de caracteres de campo - :count',
  validationMinLengthField: 'Número mínimo de caracteres de campo - :count',
  validationRequired: 'Complete este campo',
  validationEmail: 'Introduzca un correo electrónico válido',
  validationUrl: 'Ingrese una URL válida',
  needChangePhone: '¿Necesitas cambiar tu número?',
  needMoreInfo: '¿Necesitas más información?',
  learnMore: 'Aprende más',
  startUsingHola: 'Empiece a utilizar sayHola para ver el espacio social <br>',
  startUsingHolaFollow: 'Comience a usar sayHola para seguir',
  socialSpace: 'espacio social',
  socialSpaceInvite: 'Invitación al espacio social',
  socialSpaceInviteMessage: 'un espacio privado seguro para empresas, celebridades, blogueros, personas influyentes, comunidad e individuos sin comentarios ni noticias falsas',
  enterPhone: 'Ingrese el teléfono',
  payNow: 'Pagar ahora',
  formCardName: 'Nombre del titular de la tarjeta',
  formCardNumber: 'Número de tarjeta',
  formExpDate: 'Fecha de caducidad',
  formCvv: 'CVV',
  errorEmptyField: 'Este campo es obligatorio',
  paymentBtnPay: 'Pagar ahora',
  errorTitle: 'Error',
  error: 'Perdón, algo salió mal',
  errorEmailInvalid: 'Introduzca una dirección de correo electrónico válida',
  errorPhoneInvalid: 'Numero de telefono invalido',
  errorStripe: 'Por favor, asegúrese de completar todos los campos correctamente.',
  offer: 'Oferta',
  enterCode: 'Introduzca el código',
  enterPin: 'Ingrese su PIN',
  enterCardholderName: 'Ingrese el nombre del titular de la tarjeta',
  logout: 'Cerrar sesión',
  confirmation: 'Confirmación',
  thanksJoining: 'Gracias por unirte',
  successText: 'Visite Preguntas frecuentes y aprenda a utilizar las numerosas funciones especiales. Comuníquese con el soporte para preguntas adicionales. Que te gustaría hacer?',
  downLoadApp: 'Descargar aplicación',
  returnToHola: 'Regresar a Hola',
  addSponsor: 'Agregar patrocinador',
  videoAndFAQ: 'Videos y preguntas frecuentes',
  pin_unconfirmed: 'Ingrese el pin correcto!',
  selectPackage: 'Seleccionar paquete',
  offerExpired: 'Oferta especial caducada.',
  jobBrief: 'Resumen de trabajo',
  responsibilities: 'Responsabilidades',
  requirements: 'Requisitos',
  currentJobPositions: 'Oportunidades laborales actuales',
  sendCampaign: 'Enviar campaña',
  footerPrivateAppJobsPage: 'Oportunidades de trabajo',
  enterLatinLetter: 'Este campo solo puede contener caracteres alfanuméricos, guiones y guiones bajos [a-z0-9-_]',
  nicknameExist: '¡El apodo ya existe!',
  successCreateProfile: '¡Perfil creado con éxito!',
  downloadApp: 'descarga la aplicación',
  faqAndVideo: 'faq y video',
  congratulations: 'Felicidades',
  hosSubmitMessage: 'Cómo enviar su solicitud:',
  profilePicture: 'Foto de perfil',
  profilePictureMessage: 'Agrega tu foto de perfil de Social Space, queremos saber con quién estamos chateando.',
  chatProfilePictureMessage: 'Agrega tu foto de perfil, queremos saber con quién estamos chateando.',
  firstName: 'Primer nombre',
  lastName: 'Apellido',
  nickname: 'Apodo',
  location: 'Ubicación (ciudad, país)',
  btnNext: 'Próximo',
  btnFinish: 'Terminar',
  email: 'Correo electrónico',
  emailMessage: 'Su correo electrónico se utilizará solo para la recuperación. Al ingresar su correo electrónico, acepta la política de privacidad y los términos y condiciones de sayHola. Se enviarán enlaces a su correo electrónico para su recuperación.',
  changesSaved: '¡Cambios guardados!',
  emptyProfilePictureField: 'Sube tu foto de perfil',
  register: 'Registrarse',
  resendOtpMessage: 'Se envió un SMS más.',
  loginByQRCodeMessage: 'Código QR para iniciar sesión a través de un teléfono inteligente',
  authOption: 'Seleccione una opción para iniciar sesión.',
  newRegistration: 'nuevo registro',
  registrationOption: 'El registro requiere un correo electrónico y un nombre de usuario para utilizar los servicios. Una vez que haya iniciado sesión, tiene la opción de sincronizar con otras aplicaciones de mensajería usando su número de teléfono',
  loginWithApp: 'iniciar sesión con la aplicación',
  loginWithAppOption: 'Abra Hola en su teléfono, vaya al menú ubicado en la esquina superior derecha de la aplicación. Haga clic en el icono del código QR. Apunte su teléfono a esta pantalla para confirmar el inicio de sesión.',
  recoveryOption: 'recuperación',
  recoveryOptionText: 'Para recuperación Ingrese su correo electrónico registrado para iniciar sesión usando Hot-link. Obtenga más información visitando la sección de preguntas frecuentes',
  enterEmailRecovery: 'Ingrese su correo electrónico registrado para la recuperación:',
  startUsingSayHola: 'Empezar a usar sayHola',
  formEmail: 'Tu correo electrónico',
  notFoundUserLabel: 'Usuario con este correo electrónico no encontrado',
  successSendRecoveryEmailMessage: '¡Revisa tu correo electrónico y sigue las instrucciones!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programa de Agencia BDTL',
  agencyTerms: 'Términos de la agencia',
  holaExtensions: 'Hola Extensión',
  holaBlog: 'Hola Blog',
  authSlogan1: 'Ahora estamos reclutando conductores de entrega de alimentos y productos en América Latina',
  authSlogan2: 'Pagamos las tarifas de viaje más altas: $2 por viaje más extra por KM',
  authSlogan3: 'Despaxo no cobra comisiones a los conductores',
  authSlogan4: 'Sé tu propio jefe - Gana dinero súper rápido',
  authSlogan5: '¡Regístrese hoy!',
  loginByEmail: 'Iniciar sesión por correo electrónico',
  registerByEmail: 'Regístrese por correo electrónico',
  registerToday: 'Regístrese hoy.',
  fieldFirstNameLabel: 'Nombre',
  fieldLastNameLabel: 'Apellido',
  fieldEmailLabel: 'Correo electrónico',
  fieldNicknameLabel: 'Apodo',
  emailWarning: "Tu correo electrónico se utilizará solo para recuperación. Al ingresar tu correo electrónico, aceptas la política de privacidad y los términos y condiciones de sayHola. Se enviarán enlaces a tu correo electrónico para recuperación.",
  stepYourInfo: "Tu información",
  stepLicense: "Licencia",
  stepIdentification: "Identificación",
  stepVehicleInfo: "Información del vehículo",
  stepPoliceReport: "Certificado de Antecedentes Policiales",
  stepAcceptance: "Aceptación",
  fieldBirthLabel: "Fecha de nacimiento",
  uploadPhoto: 'Cargar foto',
  changeImage: 'Cambiar imagen',
  orDragDrop: 'o simplemente arrástralo y suéltalo',
  send: 'Enviar',
  cancel: 'Cancelar',
  save: 'Guardar',
  done: 'Hecho',
  continue: 'Continuar',
  fieldProfilePhotoLabel: 'Foto de perfil',
  fieldLicenseNumberLabel: "Número de licencia",
  fieldPhotoFrontLabel: "Foto delantera",
  fieldPhotoBackLabel: "Foto trasera",
  fieldIdConfirmationLabel: "Confirmación de identificación",
  fieldLicensePlateNumberLabel: "Número de placa de matrícula",
  fieldYearVehicleLabel: "Año del vehículo",
  idConfirmHint: "Foto con tu licencia de conducir",
  photoVehicleLicensePlate: "Foto de tu vehículo con la placa de matrícula",
  photoVehicleRegistrationCardFront: "Foto del frente de la tarjeta de registro del vehículo",
  photoVehicleRegistrationCardBack: "Foto del reverso de la tarjeta de registro del vehículo",
  iAgreeWith: "Acepto los ",
  termsConditions: "términos y condiciones",
  fieldSignatureLabel: "Firma",
  fieldCountryDrivingLabel: "Seleccionar país de conducción para",
  despaxoDeliveryPolicySlogan1: 'DESPAXO DELIVERY POLICY',
  despaxoDeliveryPolicySlogan2: 'This Delivery Services Agreement (the “Agreement”) is made as of today’s current date, which is logged and time stamped upon the acceptance of the Agreement, by and between xCommunications, LLC., a St Kitts and Nevis Company, owner of the Despaxo Platform  (“Company”), and the Independent Contractor of whom is reviewing and accepting said Agreement.  (“Contractor”). ',
  despaxoDeliveryPolicySlogan3: 'RECITALS ',
  despaxoDeliveryPolicySlogan4: 'A. The Company provides the opportunity to connect Contractors, Merchants, and Consumers. Consumer’s use the Company’s platform to place orders with Merchants.  The Company’s platform notifies Contractor of the opportunity to provide a service to the consumer.  The Company is not a merchant, restaurant, food delivery service, or food preparation business.  The Company is a platform that acts as a marketplace connecting and assisting in the facilitation of delivery services from the Merchant to the Consumer via Contractor.  ',
  despaxoDeliveryPolicySlogan5: 'B. Contractor is a legally licensed and insured, independent provider of delivery services. Contractor is in lawful possession of and legally permitted to operate all equipment, including, but not limited to, wireless smartphone and vehicle and/or bicycle, necessary to meet general industry standards (“General Industry Standard”) and consumer expectations (“Consumer Expectations”) of completing delivery opportunities (“Delivery Opportunity”) in the most efficient method (“Most Efficient Method”) (definitions below).  Contractor understands and agrees that Contractor is NOT an employee of the Company.  Contractor understands that there is no guarantee of Delivery Opportunities available, and Contractor is free to select the times to be available on the platform to receive Delivery Opportunities that Contractor may or may not choose to accept.',
  despaxoDeliveryPolicySlogan6: 'In consideration of the above recitals and mutual assurances between the Company and Contractor below, the Company and Contractor agree to the following: ',
  despaxoDeliveryPolicySlogan7: '1. Delivery Services.  During the term of this Agreement, Contractor will have the opportunity to provide delivery services from Merchants to consumers using the Company’s platform (the “Services”). Depending on the nature of the delivery and the requirements of the Company and/or consumers and Merchants, such Services may be carried out by means of any combination of (a) foot travel by Contractor, (b) use of Contractor’s personal bicycle, (c) use of Contractor’s personal vehicle, or (d) use of Contractor’s personal mobile device, tablet, or computer.  Contractor acknowledges that the Company has the discretion as to which, if any, Delivery Opportunities has to offer, and Contractor has discretion as to which, if any, Delivery Opportunities to accept.  ',
  despaxoDeliveryPolicySlogan8: '2. Payment for Services.  As consideration for the Services to be provided by Contractor and other obligations, the Company has engineered, through the Despaxo platform for the Contractor to be paid directly upon performance of duties and at a rate clearly noted on the Despaxo platform (the “Compensation Rate”).  Contractor Compensation Rate is a combination of a set rate, plus an incremental increase based off of KM distance from the merchant to consumer and may differ in amount accounting for variables of individual Delivery Opportunities.',
  despaxoDeliveryPolicySlogan9: 'Opportunities available to Contractor to accept, and consumer payment behavior.  Unless otherwise notified by the Company in writing or except as provided herein, Contractor will receive payment for Services at the current Compensation Rate by means designated by the Company.  Payments will be deposited directly into Contractor wallet accounts located at myxpay.net on an immediate basis or as soon as the delivery has been executed. ',
  despaxoDeliveryPolicySlogan10: '3. Expenses.  Contractor shall not be authorized to incur on behalf of the Company any expenses and will be responsible for all expenses incurred while performing the Services, including without limitation gasoline, insurance coverage and maintenance for Contractor’s personal vehicle and/or bicycle, cellular device and data costs, unless otherwise agreed to in writing by the Company.  ',
  despaxoDeliveryPolicySlogan11: '4. Term and Termination.  This agreement is effective on the date executed by the Contractor and expires upon termination of either party with no written notice necessary.',
  despaxoDeliveryPolicySlogan12: '(a) General Industry Standards.  Both parties agree that while Contractor is an independent provider of delivery services, there are certain standards that are the norm within the delivery services industry.  These norms include, but may not be limited to Contractor’s interaction with consumers and Merchants, safe handling of food and non-food items requested by consumer through the platform, privacy of consumer information, interaction with the Company’s support representatives, fulfilling requested and/or accepted Delivery Opportunities and delivery opportunity periods, responsiveness to the Company support personnel regarding accepted Delivery Opportunities and delivery opportunity periods, and completing accepted Delivery Opportunities in the Most Efficient Manner; (1) once a Delivery Opportunity is accepted, completing the accepted Service is prioritized by Contractor over other jobs or personal interests, and (2) Consumer Expectations of the Services being provided are met to the fullest extent possible, taking into consideration Contractor’s responsibility for determining the method, details and means of performing the Services. ',
  despaxoDeliveryPolicySlogan13: '(b) Consumer Expectations.  Both parties agree that while Contractor is an independent provider of delivery services, the Contractor is performing Services on behalf of the consumer and Merchant, therefore either party has the right to hold Contractor providing Services responsible for certain norms surrounding the Services being provided.   These norms include, but may not be limited to Contractor providing Services in a timely (shortest method available), safe and professional manner consistent with General Industry Standard, Contractor attempting to provide the Services as close to “door to door” as possible taking into consideration logistical and legal limitations, or providing Services from supplier to a mutually agreed upon location with the consumer, food and/or non-food items are delivered to consumer representative of the condition provided by the supplier, food and/or non-food items are representative of the order the consumer placed with the supplier on the Company platform, and consumer’s personal information and property are respected by Contractor during and after Services are provided.',
  despaxoDeliveryPolicySlogan14: '5. Independent Contractor.  Contractor’s relationship with the Company will be that of an independent contractor / Professional Services and not that of an employee. ',
  despaxoDeliveryPolicySlogan15: '(a) Method of Provision of Services.  Contractor shall be solely responsible for determining the method, details and means of performing the Services.  Contractor agrees that all legal requirements necessary to perform the Services of this Agreement are met and will be maintained as required by law.  Contractor is not required to personally complete Services and both parties agree that Contractor can engage with subcontractors or personnel to complete some or all aspects of Services, subject to the requirements of this Section 5(a).',
  despaxoDeliveryPolicySlogan16: '(i) Use of subcontractors, employees, personnel or other representatives of Contractor and in the manner that the subcontractors, employees, personnel or other representatives are utilized is solely up to Contractor at Contractor’s own risk and expense.  Contractor shall be responsible for direction and control of any subcontractors, employees, personnel or other representatives it chooses to utilize in the performance and fulfillment of Services. ',
  despaxoDeliveryPolicySlogan17: '(ii) Contractor is fully and solely responsible for all payment to subcontractors, employees, personnel or other representatives due in regards to services and work performed on behalf of Contractor or upon Contractor’s request pertaining to this Agreement; including wages, benefits, expenses, required tax withholdings, unemployment contributions, and any other reimbursements or payments owed based on completed services by Contractor and all subcontractors, employees, personnel or other representatives engaged by Contractor in Services included within this Agreement.  Both parties agree that the Company shall have no responsibility for any wages, benefits, expenses, required tax withholdings, unemployment contributions, or other payroll taxes relating to Contractor or Contractor’s subcontractors, employees, personnel or other representatives.  Neither Contractor or Contractor’s subcontractors, employees, personnel or other representatives are eligible for any benefits of any kind from the Company.  ',
  despaxoDeliveryPolicySlogan18: '(iii) Contractor is fully and solely responsible for the performance and expenses of its business, including the method of sharing Delivery Opportunities with subcontractors, employees, personnel or other representatives, compliance with all federal, state and municipal laws, rules, ordinances and other requirements by governing and insuring authorities relating to the legality and competency of any subcontractors, employees, personnel or other representatives performing services on behalf of Contractor under this Agreement. This includes meeting and maintaining equipment standards as required by this Agreement as well as any criminal and/or motor vehicle record verifications.  Contractor understands that subcontractors, employees, personnel or other representatives are working on behalf of Contractor, and any material breaches of this Agreement by said subcontractors, employees, personnel or other representatives performing services on behalf of Contractor will be treated as if Contractor was personally performing the Services and follow the notification and/or termination of Agreement for cause standards agreed to within this Agreement. ',
  despaxoDeliveryPolicySlogan19: '(iv) Contractor  shall wear or display the Company’s or a Company Sponsor’s brand or logo  whether on apparel, equipment or other similar  merchandise where and when instructed. Company may provide apparel, Company will make available opportunity for Contractor to purchase equipment or merchandise to better perform the tasks associated with this Agreement.  ',
  despaxoDeliveryPolicySlogan20: '(v) Contractor agrees to not create or design any equipment or apparel with Company branding or likeness of Company branding that could be inferred as Company logo or branding for personal use and/or gain, or for use and/or gain by Contractor’s subcontractors, employees, personnel or other representatives without the express written consent of the Company. ',
  despaxoDeliveryPolicySlogan21: '(vi) Due to the inherent safety risks of having additional passengers in/on a vehicle during Services performed, Contractor is solely responsible for guaranteeing that only Contractor and/or Contractor’s subcontractors, employees, personnel or other representatives are present during the time of providing Services.  If Contractor is utilizing subcontractors, employees, personnel or other representatives to complete all or certain aspects of the Services, Contractor’s subcontractors, employees, personnel or other representatives must satisfy all terms of this Agreement as well as current Company screening and background check requirements.  Contractor agrees that all aspects of this Agreement will be included and enforceable by Contractor’s own written agreement between Contractor and subcontractor, employee, personnel or other representative.  A copy of said written agreement shall be provided to the Company no less than seven (7) days prior to Contractor utilizing services of subcontractor, employee, personnel or other representative.  Both parties acknowledge that this requirement is to ensure Contractor’s compliance with this Agreement and all terms included in this Agreement. ',
  despaxoDeliveryPolicySlogan22: '(b) No Authority to Bind Company.  Contractor acknowledges and agrees that Contractor has no authority to enter into contracts that bind the Company or create obligations on the part of the Company without the prior written authorization of the Company. ',
  despaxoDeliveryPolicySlogan23: '(c) No Benefits.  Contractor acknowledges and agrees that Contractor shall not be eligible for any Company employee benefits and, to the extent Contractor otherwise would be eligible for any Company employee benefits but for the express terms of this Agreement, Contractor (on behalf of itself and its employees) hereby expressly declines to participate in such Company employee benefits.  ',
  despaxoDeliveryPolicySlogan24: '(d) Withholding; Indemnification.  Contractor shall have full responsibility for applicable withholding taxes for all compensation paid to Contractor under this Agreement, and for compliance with all applicable labor and employment requirements with respect to Contractor’s self-employment, sole proprietorship or other form of business organization.  Contractor agrees to indemnify, defend and hold the Company harmless from any liability, or assessment of, any claims or penalties with respect to such withholding taxes, labor or employment requirements, including any liability for, or assessment of, withholding taxes imposed on the Company by the relevant taxing authorities with respect to any compensation paid to the Contractor. ',
  despaxoDeliveryPolicySlogan25: '(e) Uniforms and Equipment.  Contractor is required to purchase, lease or rent any products, equipment or services from the Company as a condition of providing Services or entering into this Agreement. The Company requires Contractor to wear a uniform, other clothing or equipment of any type bearing the Company’s name, logo or colors or that of a Company Sponsor.  ',
  despaxoDeliveryPolicySlogan26: '(f) Multiple Platforms.  Contractor has the right to perform services for others and to be available to the general public as a separately established business.  Both Contractor and the Company recognize that they are or may be engaged in similar arrangements with other and nothing in this Agreement shall prevent either party from doing business with others.  The Company does not have the right to restrict Contractor from offering Services with other entities, including businesses that may directly compete with the Company.  Provided Contractor can meet General Industry Standards, Customer Expectations, and the material terms of this Agreement, nothing in this Agreement shall prohibit Contractor from completing Services while under Agreement with the Company. ',
  despaxoDeliveryPolicySlogan27: '<strong>Acknowledgement:</strong>  Contractor understands and agrees that if Contractor was an employee of the Company, Contractor would be prohibited from working for or providing services to competitors and the Company would be able to control the dates, times and location Contractor provided Services.  Contractor’s decision to be an independent contractor is made freely, knowingly and with knowledge of the benefits that Contractor derives from being free from control of any employer, including the ability to choose, when, where, how, and whether to provide Services, as well as to provide Services to competing companies. ',
  despaxoDeliveryPolicySlogan28: '6. Contractor’s Acceptance of Providing Services.  During the term of this Agreement, the Company may notify Contractor of the opportunity to complete Services involving Merchants and consumers facilitated through the platform under the Company’s brand name.  With respect to each Delivery Opportunity accepted by Contractor, Contractor agrees to complete any of the necessary steps to fully provide the agreed upon Service, including the retrieval of the order from the supplier, and delivery of the order to consumers in a timely (shortest method available), safe and professional manner consistent with Customer Expectations and General Industry Standards.  Contractor understands and agrees that the parameters of each Delivery Opportunity are established by the consumer and/or Merchant, not the Company, and represent the end result desired, not the mean, method or manner by which Contractor is to accomplish the result. ',
  despaxoDeliveryPolicySlogan29: '7. Confidential Information.  Contractor agrees, at all times during the term of this Agreement and thereafter, to hold in strictest confidence, and not to use except for the benefit of the Company to the extent necessary to perform its obligations hereunder, and to not disclose to any person, firm, corporation or other entity, without written authorization from the Company in each instance, any Confidential Information (as defined below) that a Contractor obtains, accesses or creates during the term of this Agreement, whether or not during working hours, until such Confidential Information becomes publicly and widely known and made generally available through no wrongful act of Contractor.  Contractor further agrees not to make copies of such Confidential Information except as authorized by the Company.  “Confidential Information” means information and physical material not generally known or available outside the Company and information and physical material entrusted to the Company in confidence by third parties.  Confidential Information includes, without limitation: (i) company inventions; (ii) technical data, trade secrets, know-how, research, product or service ideas or plans, software codes and designs, developments, inventions, laboratory notebooks, processes, formulas, techniques, lists of, or information relating to, Merchants and customers, pricing methodologies, cost data, market share data, budgets or other business information disclosed to Contractor by the Company either directly or indirectly, whether in writing, electronically, orally, or by observation. ',
  despaxoDeliveryPolicySlogan30: '8. Representations, Warranties and Covenants of Contractor.',
  despaxoDeliveryPolicySlogan31: '(a) If performing any portion of the Services via use of its personal vehicle (whether automotive or non-automotive, such as a bicycle), Contractor represents and warrants that Contractor has all appropriate licenses, approvals and authority to use the personal vehicle and provide the Services.  If performing any portion of the Services via use of its personal vehicle that is automotive (such as a moped, automobile or truck), Contractor represents and warrants that (i) Contractor has valid  driver license in the country or area where Contractor will perform the Services, and (ii) Contractor is authorised to operate a motor vehicle.  The Company holds the right to verify validity of such license and request proof of validity to be readily supplied by Contractor upon request.',
  despaxoDeliveryPolicySlogan32: '(b) If performing any portion of the Services via use of its personal vehicle, Contractor represents and warrants that Contractor has obtained, and Contractor covenants and agrees to maintain in force at all times during the term of this Agreement, (i) insurance on the personal vehicle that meets or exceeds the minimum local liability insurance required by law in the area where Contractor will perform the Services; and (ii) adequate insurance to protect Contractor from (A) claims for damages because of bodily injury, sickness, disease or death that arise out of any negligent act or omission of Contractor and (B) claims for damages because of injury to or destruction of tangible or intangible property, including loss of use resulting therefrom, that arise out of any negligent act or omission of Contractor.  If requested by the Company, Contractor shall take steps for the Company to be named as additional insured or additional loss payee, as appropriate, on Contractor’s motor vehicle insurance policy or other insurance policies applicable to the Services and such insurance policies shall contain such additional endorsements as shall be reasonably required by the Company.  The Company holds the right to request proof of such insurance by Contractor and Contractor shall have valid insurance documentation readily available to be provided to the Company upon request. ',
  despaxoDeliveryPolicySlogan33: '(c) Contractor covenants and agrees that, in the course of providing the Services, Contractor will at all times comply with all applicable laws, including without limitation all traffic laws and provisions of the motor vehicle code, including such laws and provisions applicable to the operation of a non-automotive vehicle such as a bicycle, and (if applicable) otherwise operate Contractor’s personal vehicle in a safe and reasonable manner.  Contractor agrees that the Company’s platform does not require the use of a mobile phone while operating a motor vehicle and/or bicycle.  ',
  despaxoDeliveryPolicySlogan34: '(d) Contractor represents and warrants that it is not under any pre-existing obligation in conflict or in any way inconsistent with the provisions of this Agreement.  Contractor represents and warrants that Contractor’s performance of all the terms of the Agreement will not breach any agreement to keep in confidence proprietary information acquired by Contractor in confidence or it trust prior to commencement of this Agreement.  ',
  despaxoDeliveryPolicySlogan35: '(e) Indemnification.  Contractor will indemnify and hold harmless Company from and against all claims, damages, losses and expenses, including court costs and reasonable attorneys’ fees, arising out of or resulting from, and, at Company’s option, Contractor will defend Company against any action by a third party against Company that is based on (a) any breach of Contractor’s representations, warranties or covenants in this Agreement and (b) any act or omission of Contractor that results in: (i) bodily injury, sickness, disease or death; (ii) damage, injury or destruction to tangible or intangible property; (iii) the violation of any law, statute, ordinance, or regulation; or (iv) any other damage, loss, cost or expense, by whomsoever suffered, resulting or claimed to result in whole or in part from Contractor’s provision of the Services. ',
  despaxoDeliveryPolicySlogan36: '9. Solicitation of Services.  Contractor agrees that, during the term of the Agreement and for a period of one (1) year thereafter, Contractor will not solicit or attempt to solicit, for the purpose of engaging in competition with the Company (whether by Contractor or any other person or entity), any of the Company’s employees or consultants.  Contractor agrees via Section 7 that confidential information will not be shared, used, disclosed or leveraged in any other manner for the solicitation of the Company’s customers and/or Merchants. ',
  despaxoDeliveryPolicySlogan37: '10. LIMITATION ON LIABILITY.  UNDER NO CIRCUMSTANCE WILL THE COMPANY OR ITS AFFILIATES BE LIABLE TO CONTRACTOR OR TO ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES THAT RESULT FROM THIS AGREEMENT, EVEN IF THE COMPANY OR ITS AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  IN NO EVENT WILL THE COMPANY’S TOTAL LIABILITY TO CONTRACTOR FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT (WHETHER IN CONTRACT OR TORT, INCLUDING NEGLIGENCE, WARRANTY, OR OTHERWISE) EXCEED THE FEES PAYABLE BY THE COMPANY TO CONTRACTOR HEREUNDER. ',
  despaxoDeliveryPolicySlogan38: '11. Dispute Resolution - Arbitration.',
  despaxoDeliveryPolicySlogan39: 'This Agreement is covered by the Despaxo  Arbitration clause  located in the Terms of Use - DISPUTE RESOLUTION, and is copied here in its entirety, AND IS APPLICABLE TO ALL USERS OF THE DESPAXO PLATFORM.',
  despaxoDeliveryPolicySlogan41: '“PLEASE READ THIS “DISPUTE RESOLUTION” SECTION CAREFULLY. IT LIMITS THE WAYS YOU CAN SEEK RELIEF FROM DESPAXO AND REQUIRES YOU TO ARBITRATE DISPUTES ON AN INDIVIDUAL BASIS. AS STATED ABOVE, IN ARBITRATION, THERE IS NO JUDGE OR JURY AND THERE IS MORE CIRCUMSCRIBED DISCOVERY AND APPELLATE REVIEW THAN THERE IS IN COURT.',
  despaxoDeliveryPolicySlogan42: 'I. Informal Dispute Resolution Procedure.',
  despaxoDeliveryPolicySlogan43: 'There might be instances when a Dispute (as defined below) arises between you and Despaxo. In those instances, Despaxo is committed to working with you to reach a reasonable resolution; however, we can only do this if we know about and understand each other’s concerns. Therefore, for any Dispute that arises between you and Despaxo, both parties acknowledge and agree that they will first make a good faith effort to resolve it informally before initiating any formal dispute resolution proceeding in arbitration or otherwise. This includes first sending a written description of the Dispute to the other party. For any Dispute you initiate, you agree to send the written description of the Dispute along with the email address / username / phone number  associated with your account to the following location: https://dashboard.privateapp.io/support Re: (Dispute Resolution).  Your written description must be on an individual basis and also provide at least the following information: your name; a detailed description of the nature and basis of the Dispute, including any transaction details; and the specific relief sought and how it was calculated. Your written description must be personally signed by you. For any Dispute that Despaxo raises, we will send our written description of the Dispute to the email address / user name associated with your account.',
  despaxoDeliveryPolicySlogan44: 'You and Despaxo then agree to negotiate in good faith about the Dispute. This might include an informal telephonic dispute resolution conference between you and Despaxo if such a conference is requested by us. If such  a call takes place, it shall be individualized such that a separate conference must be held each time either party intends to commence individual arbitration; multiple individuals initiating claims cannot participate in the same informal telephonic dispute resolution conference. If either party is represented by counsel, that party\'s counsel may participate in the informal telephonic dispute resolution conference, but the party also must appear at and participate in the conference. This should lead to resolution, but if for some reason the Dispute is not resolved satisfactorily within sixty (60) days after receipt of the complete written description of the Dispute, you and Despaxo agree to the further dispute resolution provisions below.',
  despaxoDeliveryPolicySlogan45: 'To reiterate, this informal dispute resolution process is a prerequisite and condition precedent to commencing any formal dispute resolution proceeding. The parties agree that any relevant limitations period and filing fee or other deadlines will be tolled while the parties engage in this informal dispute resolution process. A court shall have the authority to enjoin the filing or prosecution of arbitrations based on a failure to comply with this Informal Dispute Resolution Procedure. A party may raise non-compliance with this Informal Dispute Resolution Procedure in court and/or in connection with the arbitration.',
  despaxoDeliveryPolicySlogan46: 'II. Mutual Arbitration Agreement.',
  despaxoDeliveryPolicySlogan47: 'You and Despaxo agree that all claims, disputes, or disagreements that may arise out of the interpretation or performance of this Agreement or payments by or to Despaxo, or that in any way relate to your use of the Platform, the Materials, the Services, and/or other content on the Platform, your relationship with Despaxo, or any other dispute with  Despaxo, (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory) (each, a “Dispute”) shall be submitted exclusively to binding arbitration. Dispute shall have the broadest possible meaning. This includes claims that arose, were asserted, or involve facts occurring before the existence of this or any prior Agreement as well as claims that may arise after the termination of this Agreement. This Mutual Arbitration Agreement is intended to be broadly interpreted.',
  despaxoDeliveryPolicySlogan48: 'Notwithstanding the foregoing, issues related to the scope, validity, and enforceability of this Arbitration Agreement are for a court to decide. Also, each party retains the right to (1) elect (at any time prior to the appointment of an arbitrator) to have any claims heard in small claims court on an individual basis for disputes and actions within the scope of such court’s jurisdiction, provided the proceeding remains in small claims court and is not removed or appealed to a court of general jurisdiction, and (2) seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party\'s copyrights, trademarks, trade secrets, patents, or other confidential or proprietary information or intellectual property rights. For clarity, this “Dispute Resolution” section does not alter, amend, or affect any of the rights or obligations of the parties to any Despaxo Delivery Partner Agreement.',
  despaxoDeliveryPolicySlogan49: 'ARBITRATION MEANS THAT AN ARBITRATOR AND NOT A JUDGE OR JURY WILL DECIDE THE DISPUTE. RIGHTS TO PREHEARING EXCHANGE OF INFORMATION AND APPEALS MAY BE LIMITED IN ARBITRATION. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU AND DESPAXO ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY TO THE MAXIMUM EXTENT PERMITTED BY LAW.',
  despaxoDeliveryPolicySlogan50: 'III. Class Action and Collective Relief Waiver.',
  despaxoDeliveryPolicySlogan51: 'YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT ALLOWED BY LAW, EXCEPT AS SET OUT IN SECTION VII BELOW, THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED OR LITIGATED ON A CLASS, JOINT, COLLECTIVE OR CONSOLIDATED BASIS OR IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC, OR AS A PRIVATE ATTORNEY GENERAL OR FOR PUBLIC INJUNCTIVE RELIEF. UNLESS BOTH YOU AND DESPAXO OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS (EXCEPT AS SET OUT IN SECTION VII BELOW), AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY CLASS, JOINT, COLLECTIVE OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY AWARD RELIEF (INCLUDING ANY DECLARATORY OR INJUNCTIVE RELIEF) ONLY IN FAVOUR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO RESOLVE AN INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT AWARD RELIEF FOR OR AGAINST ANYONE WHO IS NOT A PARTY TO THE PROCEEDING.',
  despaxoDeliveryPolicySlogan52: 'THIS CLASS ACTION AND COLLECTIVE RELIEF WAIVER IS AN ESSENTIAL PART OF THIS “DISPUTE RESOLUTION” SECTION, AND IF IT IS DEEMED INVALID OR UNENFORCEABLE WITH RESPECT TO A PARTICULAR CLAIM OR DISPUTE, NEITHER YOU NOR DESPAXO IS ENTITLED TO ARBITRATION OF SUCH CLAIM OR DISPUTE. NOTWITHSTANDING THE FOREGOING, IF A COURT DETERMINES THAT THE CLASS ACTION AND COLLECTIVE RELIEF WAIVER IS NOT ENFORCEABLE AS TO A PARTICULAR CLAIM OR REQUEST FOR RELIEF OR A REQUEST FOR PUBLIC INJUNCTIVE RELIEF AND ALL APPEALS FROM THAT DECISION HAVE BEEN EXHAUSTED (OR THE DECISION IS OTHERWISE FINAL), THEN THE PARTIES AGREE THAT THAT PARTICULAR CLAIM OR REQUEST FOR RELIEF MAY PROCEED IN COURT BUT SHALL BE STAYED PENDING ARBITRATION OF THE REMAINING CLAIMS AND REQUESTS FOR RELIEF.',
  despaxoDeliveryPolicySlogan53: 'IV. Arbitration Rules.',
  despaxoDeliveryPolicySlogan54: 'The arbitration will be administered by the  Laws of Saint Christopher (St Kitts) and Nevis Arbitration Act Revised 2002). Except as modified by this “Dispute Resolution” section, the Act will administer the arbitration in accordance with either (A) the Commercial Arbitration Rules than in effect, or (B) the Consumer Arbitration Rules then in effect. ',
  despaxoDeliveryPolicySlogan55: 'V. Arbitration Process.',
  despaxoDeliveryPolicySlogan56: 'If after sixty (60) days the Informal Dispute Resolution Procedure above is unsuccessful in resolving the parties’ dispute, a party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the Applicable Arbitration rules. The arbitration will be conducted by a single arbitrator. The arbitrator will be either a retired judge or an attorney licensed to practice law in the named jurisdiction. The parties will first attempt to agree on an arbitrator. If the parties are unable to agree upon an arbitrator within twenty-eight (28) days of receiving the list of eligible neutrals, then in accordance with the Arbitration Act, a tribunal will decide the arbitrator.',
  despaxoDeliveryPolicySlogan57: 'VI. Arbitration Location and Procedure.',
  despaxoDeliveryPolicySlogan58: 'As stated, the arbitration will be conducted in St Kitts and Nevis. If the amount in controversy does not exceed $10,000 and you do not seek injunctive or declaratory relief, then the arbitration will be conducted solely on the basis of documents you and Despaxo submit to the arbitrator, unless a party requests a hearing and the arbitrator determines that a hearing is necessary. If the amount in controversy exceeds $10,000 or seeks declaratory or injunctive relief, the right to a hearing will be determined by the Applicable Rules of Arbitration Act. Subject to the Applicable Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration. Unless otherwise prohibited by law or agreed by the parties, all arbitration proceedings and all related records will be confidential and closed to the public and any parties other than you and Despaxo, except as necessary to obtain court confirmation of the arbitration award.',
  despaxoDeliveryPolicySlogan59: 'VII. Batch Arbitration.',
  despaxoDeliveryPolicySlogan60: 'To increase the efficiency of administration and resolution of arbitrations, in the event 100 or more similar arbitration demands presented by or with the assistance or coordination of the same law firm or organization are submitted against Despaxo, the arbitration provider shall (i) administer the arbitration demands in batches of 100 demands per batch (to the extent there are fewer than 100 arbitration demands left over after the batching described above, a final batch will consist of the remaining demands); (ii) designate a single, different arbitrator for each batch (unless the parties agree otherwise); and (iii) provide for a single filing set of fees (for example, if The Arbitrator - St Kitts Nevis  is the arbitration provider, one filing fee, case management fee, and arbitrator compensation fee due per side per batch. You agree to cooperate in good faith with Despaxo and the arbitration provider to implement such a “batch approach” or other similar approach to provide for an efficient resolution of claims, including the payment of single filing and administrative fees for batches of claims. This “Batch Arbitrations” provision shall in no way be interpreted as authorizing class arbitration of any kind. Despaxo expressly reserves its right to raise unique defenses as to each claimant in connection with this process.',
  despaxoDeliveryPolicySlogan61: 'VIII. Arbitrator\'s Decision.',
  despaxoDeliveryPolicySlogan62: 'The arbitrator will render an award within the time frame specified in the applicable Rules. The arbitrator\'s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator will have the authority to award monetary damages on an individual basis and to grant, on an individual basis, any non-monetary remedy or relief available to an individual to the extent available under applicable law, the arbitral forum’s rules, and this Agreement. The arbitrator\'s award of damages and/or other relief must be consistent with section III above and also must be consistent with the terms of the "Limitation of Liability" section above as to the types and the amounts of damages or other relief for which a party may be held liable. The arbitrator’s award shall be binding only between the parties to the arbitration proceeding and shall have no preclusive effect in any other arbitration or other proceeding involving a different party.',
  despaxoDeliveryPolicySlogan63: 'Attorneys’ fees will be available to the prevailing party in the arbitration only if authorized under applicable substantive law governing the claims in the arbitration. The arbitrator shall apply the provisions of Civil Procedure 68 (UK) after the arbitration award is entered. If the arbitrator finds that either the substance of a claim or the relief sought in a Demand for Arbitration was frivolous or was brought for harassment or an improper purpose (as measured by the standards set forth under St Kitts and Nevis civil proceedings), the arbitrator may award attorneys’ fees, costs and expenses in favour of a party.',
  despaxoDeliveryPolicySlogan64: 'IX. Fees.',
  despaxoDeliveryPolicySlogan65: 'Your responsibility to pay any Arbitration filing, administrative, and/or arbitrator fees will be solely as set forth in the applicable Rules (The Arbitrator, St Kitts and Nevis). If, however, you are able to demonstrate that the costs of arbitration will be prohibitive for you as compared to the costs of litigation, Despaxo will reimburse as much of the filing, administration, and/or arbitrator fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive to you.',
  despaxoDeliveryPolicySlogan66: 'X. Changes.',
  despaxoDeliveryPolicySlogan67: 'Despaxo reserves the right to change this "Dispute Resolution" section. If Despaxo changes this "Dispute Resolution" section after the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement), you agree that your continued use of the Platform or Services after such change will be deemed acceptance of those changes. If you do not agree to such change, you may reject any such change by providing Despaxo written notice of such rejection by digital correspondence at: https://dashboard.privateapp.io/supportmail (Re: Rejection to new Terms) within 30 days of the date such change became effective. In order to be effective, the notice must include your full name and clearly indicate your intent to reject changes to this "Dispute Resolution" section. By rejecting changes, you are agreeing that you will arbitrate any dispute between you and Despaxo in accordance with the provisions of this "Dispute Resolution" section as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement, as applicable).”',
  despaxoDeliveryPolicySlogan68: '12. Miscellaneous.',
  despaxoDeliveryPolicySlogan69: '(a) Amendments and Waivers.  Any term of this Agreement may be amended or waived only with the written consent of the Company. ',
  despaxoDeliveryPolicySlogan70: '(b) Sole Agreement.  This Agreement constitutes the sole agreement of the parties and supersedes all oral negotiations and prior writings with respect to the subject matter hereof. ',
  despaxoDeliveryPolicySlogan71: '(c) Notices.  Any notice required or permitted by this Agreement shall be in writing and shall be deemed sufficient upon receipt, when delivered via e-message to Company at https://dashboard.privateapp.io/support ',
  despaxoDeliveryPolicySlogan72: '(Re: DRIVER CORRESPONDENCE)',
  despaxoDeliveryPolicySlogan73: '72 hours after message has been sent, and the same as to the e-mail on record for Contractor.',
  despaxoDeliveryPolicySlogan74: 'BOTH PARTIES ACKNOWLEDGE THAT NOTICES RELATING TO SECTION 4 (TERM AND TERMINATION) WILL BE CONSIDERED “IN WRITING” WHEN SENT VIA EMAIL TO THE EMAIL ADDRESS PROVIDED BY THE CONTRACTOR AND USED IN COMMUNICATION BETWEEN THE COMPANY AND CONTRACTOR. CONTRACTOR HAS A RIGHT TO REQUEST ADDITIONAL WRITTEN CONFIRMATION OF THE NOTICE(S) MEETING THE STANDARDS INCLUDED IN SECTION 12 (C) WHEN EMAIL CORRESPONDENCE IS USED; HOWEVER, CONTRACTOR’S FAILURE TO REVIEW THE NOTICE(S) SENT VIA EMAIL WILL NOT HAVE ANY IMPACT ON THE COMMUNICATIONS INCLUDED IN THE EMAIL NOTICE AS LONG AS THE COMPANY IS ABLE TO PROVIDE VERIFICATION THAT THE EMAIL WAS SENT TO THE EMAIL ADDRESS PROVIDED BY THE CONTRACTOR.  ',
  despaxoDeliveryPolicySlogan75: '(d) Choice of Law.  Except as provided in Section 11, the validity, interpretation, construction and performance of this Agreement shall be governed by the laws of Federation of St Christopher (St Kitts) and Nevis, without giving effect to the principles of conflict of laws. ',
  despaxoDeliveryPolicySlogan76: '(e) Severability.  If one of more provisions of the Agreement are held to be unenforceable under applicable law, the parties agree to renegotiate such provision in good faith.  In the even that the parties cannot reach a mutually agreeable and enforceable replacement for such provision, then (i) such provision shall be excluded from this Agreement, (ii) the balance of the Agreement shall be interpreted as if such provision were so excluded and (iii) the balance of the Agreement shall be enforceable in accordance with its terms. \n',
  despaxoDeliveryPolicySlogan77: '(f) Counterparts.  This Agreement may be executed digitally where each document shall be deemed an original, but all of which together will constitute one and the same instrument.',
  despaxoDeliveryPolicySlogan78: '(g) Advice of Counsel.  EACH PARTY ACKNOWLEDGES THAT, IN EXECUTING THIS AGREEMENT,SUCH PARTY HAS HAD THE OPPORTUNITY TO SEEK THE ADVICE OF INDEPENDENT LEGAL COUNSEL, AND HAS READ AND UNDERSTOOD ALL OF THE TERMS AND PROVISIONS OF THIS AGREEMENT.  THIS AGREEMENT SHALL NOT BE CONSTRUED AGAINST ANY PARTY BY REASON OF THE DRAFTING OR PREPARATION HEREOF. ',
  despaxoDeliveryPolicySlogan79: 'AGREEMENT ACCEPTANCE STATEMENTS ',
  despaxoDeliveryPolicySlogan80: 'A. By clicking the agreeing below, I am stating that I fully understand that the Agreement between the Company and I is one of an Independent Contractor / Professional Services Provider and not of an employee. ',
  despaxoDeliveryPolicySlogan81: 'B. By agreeing below, I am stating that I have all necessary equipment, licenses and insurance to meet standards of this agreement.  ',
  despaxoDeliveryPolicySlogan82: 'C. By agreeing below, I am stating that I understand and consent to Mutual Arbitration, waiving any right to trial by jury and participation in class, collective, or representative action.',
  despaxoDeliveryPolicySlogan83: 'D. I am legally competent to enter into this Agreement and voluntarily agree to be bound by the terms of this Agreement.',
  despaxoDeliveryPolicySlogan84: 'LAST UPDATED : 03 APRIL 2023',
  back: 'Volver',
  registration: "Registro",
  agree: "Aceptar",
  skip: "Saltar",
  backgroundCheck: 'Verificación de antecedentes',
  provideTripsWithMyCar: "Proporcionar viajes con mi coche",
  someoneToDriveMyVehicle: "Quiero que alguien conduzca mi vehículo",
  driveButDontHaveCar: "Quiero conducir pero no tengo coche",
  deliverOnMoto: "Quiero hacer entregas en moto",
  deliverOnBicycle: "Quiero hacer entregas en bicicleta",
  deliverSmallFreight: "Quiero hacer entregas de carga pequeña",
  deliverLargeFreight: "Quiero hacer entregas de carga grande",
  offerAllTerrainRides: "Quiero ofrecer paseos todo terreno 4x4",
  offerGroupsSmallShuttleRides: "Quiero ofrecer paseos en minibús para grupos pequeños",
  offerRidesByMoto: "Quiero ofrecer paseos en moto",
  fieldDriverTypeLabel: "Tipo de conductor",
  fieldConfirmCodeLabel: "Código",
  confirmEmailAddress: "Confirmar correo electrónico",
  confirmEmailAddressHint: "Te hemos enviado un correo electrónico de confirmación. Por favor, ingresa el código de seis dígitos que te hemos enviado.",
  confirm: "Confirmar",
  serverError_otp_unconfirmed: "Código OTP incorrecto",
  serverError_exists: "Este campo ya existe",
  fieldIdNumberLabel: "Número de identificación",
  create: "Crear",
  registrationDesc1: "Gracias por tu interés en los Servicios de Despaxo Driver",
  registrationDesc2: "Por favor, completa el Formulario de Registro. Una vez aprobado, recibirás una notificación a través de los servicios de Hola Chat.",
  registrationDesc3: "Debes saber que durante el Registro deberás completar la mayoría de las partes.",
  registrationDesc4: "Tu perfil podrá ser editado después del proceso de Registro descargando Hola Chat o iniciando sesión a través del panel de control web.",
  registrationDesc5: "Ten en cuenta que ser un Conductor de Despaxo es una posición activa y demanda no solo amor por los clientes, sino también el deseo de ganar a través del compromiso: siempre tratando de ser el mejor proveedor de servicios.",
  registrationDesc6: "Y ahora, la buena noticia:",
  registrationDesc7: "¡La plataforma Despaxo no cobra comisiones a nuestros conductores!",
  registrationDesc8: "Además, Despaxo ofrece formas realmente geniales de ganar más dinero. ¡Solo pregúntale a tu Patrocinador!",
  registrationDesc9: "Pero una cosa es segura: deja de \"pagar\" tus comisiones duramente ganadas a otras aplicaciones de reparto por demasiado trabajo y poco salario.",
  registrationDesc10: "Únete a la Revolución de Entregas de Despaxo y mantén más dinero en tu propio bolsillo.",
  registrationDesc11: "Regístrate hoy mismo y sé parte activa de nuestro equipo emocionante.",
  getStarted: "Empezar",
  pendingApproval: "Pendiente de aprobación",
  notRegisteredUserDriverHint: "¿No estás registrado como conductor de Despaxo? Comienza el proceso ahora mismo.",
  acceptAgreement: "Aceptar acuerdo",
  chooseOptions: "-elegir una opción-",
  fieldPhoneLabel: "Teléfono",
  enterPhoneNumber: "Introduce el número de teléfono",
  iHaveHolaProfile: "Ya tengo una cuenta en Hola",
  confirmOtp: "Confirmar OTP",
  validationError_phone_exists: "Este teléfono ya está en uso",
  validationError_phone_invalid: "Número de teléfono no válido",
  validationError_phone_required: "Por favor, complete este campo",
  declinedProfile: "Lamentablemente, tu perfil no cumple con los requisitos para obtener el estado de conductor en nuestra plataforma en este momento. Gracias por tu comprensión.",
  pendingProfile: "Tu perfil está actualmente en revisión para obtener el estado de conductor. Apreciamos tu paciencia y te contactaremos una vez que la revisión esté completa.",
  registrationTitle: "Servicios de Conductor Despaxo",
  settings: "Configuración",
  profile: "Perfil",
  license: "Licencia",
  licensePhotoFront: "Foto del Frente de la Licencia",
  licensePhotoBack: "Foto del Dorso de la Licencia",
  licenseIdConfirmation: "Confirmación de Identificación de Licencia",
  countryDrivingFor: "País para el que Conduces",
  update: "Actualizar",
  orders: "Pedidos",
  wallet: "Billetera",
  menu: "Menú",
  verifyAccount: "Verificar cuenta",
  satisfactionRating: "Índice de Satisfacción",
  yourBalance: "Tu Saldo",
  successDeliveries: "Entregas Exitosas",
  history: "Historial",
  home: "Inicio"
}
