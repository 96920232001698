import axios from 'axios'
import applyConverters from 'axios-case-converter'
import qs from 'qs'
import store from '@/store'
import { REFRESH_TOKEN_URL } from './auth'
import {getAccessToken} from '@/helpers/tokens'
import {toSnakeCase} from '@/helpers'
const camelcaseObjectDeep = require('camelcase-object-deep')

const instance = applyConverters(axios.create({
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  }
}))

let refreshTokenPromise

instance.interceptors.request.use(
  config => {

    const isAuthenticated = !!localStorage.getItem('accessToken')
    if (isAuthenticated) {
      config.headers.Authorization = `Bearer ${getAccessToken()}`
    } else {
      delete config.headers.Authorization
    }
    if (config.method !== 'get' && config.data) {
      config.data = toSnakeCase(config.data, Array.isArray(config.data) ? 'array' : 'object')
      return config
    }
    if (config.data) {
      config.data = toSnakeCase(config.data)
    }
    return config
  },
  error => Promise.reject(error)
)
instance.interceptors.response.use(
  response => {
    return camelcaseObjectDeep(response)
  },
  async error => {
    if (error.response.status === 403) {
      const { errorType, data: { type } } = error.response.data
      if (errorType === 'access' && type === 'expired' && error.config.url !== REFRESH_TOKEN_URL) {
        await refreshTokenPromise
        refreshTokenPromise = null
        return instance(error.config)
      }
    }
    throw error
  }
)

export default instance
