export const isMobile = {
  Android: function () {
    return !!navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return !!navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Windows: function () {
    return !!navigator.userAgent.match(/IEMobile/i)
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows())
  }
}
export const isIPad = {
  iOS: function () {
    return !!navigator.userAgent.match(/iPad|iPod/i)
  }
}

export const isIOSDevice = () => {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
}


export function menuList () {
  const isAuth = !!localStorage.getItem('accessToken')
  return  [
    {text: this.$t('home'), icon: 'mdi-home', to: 'dashboard', show: isAuth && this.$route.name !== 'registration'},
    {text: this.$t('history'), icon: 'mdi-history', to: 'history', show: isAuth && this.$route.name !== 'registration'},
    {text: this.$t('settings'), icon: 'settings', to: 'settings', show: isAuth && this.$route.name !== 'registration'},
    {text: this.$t('wallet'), icon: 'wallet', to: 'wallet', show: isAuth && this.$route.name !== 'registration'},
    {text: this.$t('logout'), icon: 'logout', to: 'logout', show: isAuth && this.$route.name !== 'registration'},
    {text: this.$t('login'), icon: 'login', to: 'auth', show: !isAuth || this.$route.name === 'registration'},
  ]
}
