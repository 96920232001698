export default {
  // footer
  termOfService: '服務期限',
  privacyPolicy: '隱私政策',
  xCommunications: 'x通訊',
  xCommunicationsInvestorRelations: '投資者關係',
  xCommunicationsMediaRequest: '媒體請求',
  xCommunicationsOurPositionPrivacy: '我們對隱私的立場',
  xCommunicationsKnowledgeBase: '知識庫',
  xCommunicationsOurPosition: '我們對網絡欺凌的立場',
  premiumTools: '高級工具',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat插件',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog插件',
  premiumToolsTogetherSayHola: '一起說sayHola',
  premiumToolsLiveLeagueApp: '直播聯賽應用',
  programs: '程式',
  programsAdvertiseHola: '用Hola做廣告',
  programsFreelancerProgram: '自由職業者計劃',
  programsAffiliateRewardsProgram: '會員獎勵計劃',
  terms: '條款',
  termsAdsPolicy: '廣告政策',
  termsPrivacyPolicy: '隱私政策',
  termsCommunityStandards: '社區標準',
  termsTermsUse: '使用條款',
  termsLoyaltyRewardsTerms: '忠誠度獎勵條款',
  // Home Page
  homeSlider: {
    haveAccount: '已經有賬戶？',
    changeNumber: '需要更改您的號碼嗎？',
    getStartedButton: '現在就開始！',
    sliderPrevButton: '後退',
    sliderNextButton: '繼續閱讀',
    slogan1: 'Hola Cross Platform Chatbot Bridge 技術平台可幫助您將用戶轉變為長期客戶。使用 Chatbot Bridge，您可以從任何聊天應用程序（WhatsApp、Telegram 等）生成客戶端，並在一個儀表板下管理它們。',
    slogan2: '我們的技術解決方案包括適用於所有服務類型的“易於構建模板”，從“食品訂單”到“客戶支持”，僅使用您選擇和使用的功能。',
    slogan3: '您可以使用基於按鈕的聊天機器人構建模板來繪製客戶對話。通過預測客戶可能提出的問題類型，聊天機器人被編程為做出適當的響應，從而使客戶體驗用戶友好和真實。',
    slogan4: '查看我們的演示或立即登錄並成為高級客戶開始使用',
    needMoreInfo: '需要更多信息？',
    learnMore: '學到更多'
  },
  signIn: '登入',
  recovery: '恢復',
  otp_unconfirmed: 'otp未確認，再試一次',
  otpDescription: '短信代碼已發送給您。請在下面輸入代碼。',
  otpEnterYourOtp: '輸入您的 otp 代碼',
  otpEnterYourPin: '輸入您的密碼',
  verify_otp: '確認',
  otpDontReceiveCode: '沒有收到驗證碼？',
  otpRequestSMSAgain: '再次請求短信',
  otpRequestNewCode: '請求一個新的代碼',
  copyright: 'x通訊。版權所有',
  enterPhoneNumber: '請選擇您的國家並輸入您的完整電話號碼。',
  login: '登錄',
  validationSelectFile: '選擇並上傳照片',
  validationSameAs: '密碼不匹配',
  validationMaxLengthField: '最大字段字符數 - :count',
  validationMinLengthField: '最少字段字符數 - :count',
  validationRequired: '填寫此字段',
  validationEmail: '輸入有效的電子郵件',
  validationUrl: '輸入有效的網址',
  needChangePhone: '需要更改您的號碼嗎？',
  needMoreInfo: '需要更多信息？',
  learnMore: '學到更多',
  startUsingHola: '開始使用 sayHola 查看 <br> 社交空間',
  startUsingHolaFollow: '開始使用 sayHola 關注',
  socialSpace: '社交空間',
  socialSpaceInvite: '社交空間邀請',
  socialSpaceInviteMessage: '為企業、名人、博主、有影響力的人、社區和個人提供安全可靠的私人空間，沒有評論和虛假新聞',
  enterPhone: '輸入電話',
  payNow: '現在付款',
  formCardName: '持卡人姓名',
  formCardNumber: '卡號',
  formExpDate: '到期日',
  formCvv: '變異係數',
  errorEmptyField: '此字段是必需的',
  paymentBtnPay: '現在付款',
  errorTitle: '錯誤',
  error: '抱歉，出了一些問題',
  errorEmailInvalid: '輸入一個有效的電子郵件地址',
  errorPhoneInvalid: '無效的電話號碼',
  errorStripe: '請確保您正確填寫所有字段',
  offer: '提供',
  enterCode: '輸入代碼',
  enterPin: '輸入密碼',
  enterCardholderName: '輸入持卡人姓名',
  logout: '登出',
  confirmation: '確認',
  thanksJoining: '感謝您的加入',
  successText: '訪問常見問題並了解如何使用許多特殊功能。如有其他問題，請聯繫支持人員。你想幹什麼？',
  downLoadApp: '下載應用程序',
  returnToHola: '返回霍拉',
  addSponsor: '添加贊助商',
  videoAndFAQ: '視頻和常見問題解答',
  pin_unconfirmed: '輸入正確的引腳！',
  selectPackage: '選擇套餐',
  offerExpired: '特價已過期！',
  jobBrief: '工作簡介',
  responsibilities: '職責',
  requirements: '要求',
  currentJobPositions: '當前工作機會',
  sendCampaign: '發送活動',
  footerPrivateAppJobsPage: '工作機會',
  enterLatinLetter: '此字段只能包含字母數字字符、破折號和下劃線 [a-z0-9-_]',
  nicknameExist: '暱稱已經存在！',
  successCreateProfile: '個人資料創建成功！',
  downloadApp: '下載應用程序',
  faqAndVideo: '常見問題和視頻',
  congratulations: '恭喜',
  hosSubmitMessage: '如何提交您的申請：',
  profilePicture: '個人資料圖片',
  profilePictureMessage: '添加您的社交空間個人資料圖片，我們想知道我們正在與誰聊天。',
  chatProfilePictureMessage: '添加您的個人資料圖片，我們想知道我們正在與誰聊天。',
  firstName: '名',
  lastName: '姓',
  nickname: '暱稱',
  location: '地點（城市、國家）',
  btnNext: '下一個',
  btnFinish: '結束',
  email: '電子郵件',
  emailMessage: '您的電子郵件將僅用於恢復。輸入您的電子郵件，即表示您接受 sayHola 隱私政策和條款和條件。鏈接將發送到您的電子郵件以供恢復。',
  changesSaved: '更改已保存！',
  emptyProfilePictureField: '請上傳您的個人資料圖片',
  register: '登記',
  resendOtpMessage: '又發了一條短信。',
  loginByQRCodeMessage: '通過智能手機登錄的二維碼',
  authOption: '請選擇登錄選項。',
  newRegistration: '新註冊',
  registrationOption: '註冊需要電子郵件和用戶名才能使用服務。登錄後，您可以選擇使用您的電話號碼與其他信使應用程序同步',
  loginWithApp: '使用應用程序登錄',
  loginWithAppOption: '在您的手機上打開 Hola，轉到位於應用程序右上角的菜單。單擊二維碼圖標。將您的手機指向此屏幕以確認登錄。',
  recoveryOption: '恢復',
  recoveryOptionText: '為恢復輸入您的註冊電子郵件以使用熱鏈接登錄。通過訪問常見問題部分了解更多信息',
  enterEmailRecovery: '輸入您註冊的電子郵件以進行恢復：',
  startUsingSayHola: '開始使用 sayHola',
  formEmail: '你的郵件',
  notFoundUserLabel: '未找到使用此電子郵件的用戶',
  successSendRecoveryEmailMessage: '檢查您的電子郵件並按照說明進行操作！',
  chatBots: '聊天機器人',
  BdtlAgencyProgram: 'BDTL 代理計劃',
  agencyTerms: '代理條款',
  holaExtensions: 'Hola 擴展',
  holaBlog: '你好博客',
  authSlogan1: '我們現在正在拉丁美洲招聘食品和產品的送貨司機',
  authSlogan2: '我們支付最高的旅行費率：每次旅行 2 美元加上每公里的額外費用',
  authSlogan3: 'Despaxo 不收取司機佣金',
  authSlogan4: '自己當老闆 - 超快收款',
  authSlogan5: '今天註冊！',
  loginByEmail: '通過電子郵件登錄',
  registerByEmail: '通過電子郵件註冊',
  registerToday: '立即註冊。'
}
