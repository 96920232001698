<template>
  <SecondaryModal
    :name="$options.name"
    :clickToClose="false"
    @before-open="beforeOpen"
    class="recovery-popup">
    <div class="recovery-wrap">
      <template v-if="step === 1">
        <div class="recovery">
          <div class="text-center mb-4" style="font-size: 26px; font-weight: 600;">
            {{ title }}
          </div>
          <v-form class="recovery-form"
                  v-model="validEmailForm"
                  ref="emailForm"
                  @submit.prevent="handleSendEmail">
            <div class="form-field">
              <div class="form-field__label text-left">
                {{ $t('formEmail') }}
              </div>
              <div class="form-field__body">
                <v-text-field
                  v-model="email"
                  class="mt-0 pt-0 text-center"
                  :rules="emailRules"
                  type="email"
                  name="email"
                  hide-details="auto"
                />
              </div>
            </div>
            <div class="d-flex justify-center align-center mt-4">
              <v-btn
                type="submit"
                :loading="loading"
                color="secondary"
              >
                {{ $t('verify_otp') }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </template>
      <template v-else>
        <v-form class="form pt-0"
                v-model="validConfirmForm"
                @submit.prevent="handleConfirmEmailCode"
                ref="confirmForm">
          <div class="text-center" style="font-size: 26px; font-weight: 600;">
            {{ $t('confirmEmailAddress') }}
          </div>
          <p class="mt-3" style="line-height: 1.4;">
            {{ $t('confirmEmailAddressHint') }}
          </p>
          <v-row class="mb-1 mt-5 ">
            <v-col cols="12" class="pt-0 pb-0">
              <div class="form-field">
                <div class="form-field__label text-left">
                  {{ $t('fieldConfirmCodeLabel') }}
                </div>
                <div class="form-field__body">
                  <v-text-field
                    v-model="code"
                    :error-messages="serverErrors?.code || serverErrors?.type && $t(`serverError_${serverErrors?.type}`)"
                    class="mt-0 pt-0 text-center"
                    :rules="codeRules"
                    placeholder="######"
                    name="code"
                    type="number"
                    hide-details="auto"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="d-flex justify-center align-center mt-4">
            <v-btn
              type="submit"
              :loading="loadingEmailOtp"
              color="secondary"
            >
              {{ $t('confirm') }}
            </v-btn>
          </div>
        </v-form>
      </template>
    </div>
  </SecondaryModal>
</template>

<script>
import SecondaryModal from './SecondaryModal'
import BaseButton from '@/components/atoms/BaseButton.vue'
import BaseInput from '@/components/atoms/BaseInput.vue'
import {mapActions, mapGetters} from 'vuex'
import validation from '@/mixins/validation'
import {saveTokensToStorage} from "@/helpers/tokens";
const device = {
  id: '73617948-6f6c-6120-426c-6f6720777000',
  type: 'web'
}

export default {
  name: 'sign-in',
  mixin: [validation],
  components: {
    SecondaryModal,
    BaseButton,
    BaseInput
  },
  data() {
    return {
      email: '',
      loading: false,
      validConfirmForm: false,
      validEmailForm: false,
      loadingEmailOtp: false,
      serverErrors: null,
      isError: false,
      step: 1,
      title: null,
      code: ''
    }
  },
  computed: {
    ...mapGetters({
      otpResponse: 'auth/otpResponse',
      isAuthenticated: 'auth/isAuthenticated',
      socialInfo: 'auth/socialInfo'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ]),
  },
  methods: {
    ...mapActions({
      registerRequest: 'auth/signIn',
      confirmEmailOtp: 'auth/confirmEmailOtp',
    }),
    beforeOpen(item) {
      const {title} = item.params
      this.title = title
      this.resetForm()
    },
    resetForm() {
      this.isError = false
      this.email = ''
      this.code = ''
      this.step = 1
      this.serverErrors = null
      this.$refs?.confirmForm?.resetValidation()
      this.$refs?.emailForm?.resetValidation()
    },
    handleConfirmEmailCode() {
      this.serverErrors = null
      this.$refs.confirmForm.resetValidation()
      setTimeout(() => {
        this.$refs.confirmForm.validate()
        if (this.validConfirmForm) {
          this.confirmEmailCode()
        }
      }, 10)
    },
    handleSendEmail(){
      this.$refs.emailForm.validate()
      if (this.validEmailForm) {
        this.sendEmail()
      }
    },
    async sendEmail() {
      this.loading = true
      try {
        await this.registerRequest({
          email: this.email,
          lang: this.currentLanguage.requestKey,
          device
        })
        this.step = 2
      } finally {
        this.loading = false
      }
    },
    async confirmEmailCode() {
      this.loadingEmailOtp = true
      this.serverErrors = null
      try {
        const response = await this.confirmEmailOtp({
          email: this.email,
          otp: this.code,
          device
        })
        saveTokensToStorage(response.token)
        this.$modal.hide(this.$options.name)
        await this.$router.push({ name: 'dashboard' })
        setTimeout(() => {
          location.reload()
        }, 500)
      } catch (err) {
        this.serverErrors = err?.data
      } finally {
        this.loadingEmailOtp = false
      }
    },
  }
}
</script>
<style lang="scss">
.recovery-popup {
  .vm--overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
  }

  .vm--modal {
    @include respondTo(500px) {
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 0;
      left: auto !important;
    }
  }

  .recovery-form {
    .form--input {
      border: 1px solid rgba(17, 17, 17, 0.15);
      transition: border-color $trans;
      padding: 0 10px;

      &:focus {
        border: 1px solid rgba(17, 17, 17, 1);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.recovery {
  @include respondTo(500px) {
    margin: 0 auto;
    padding: 0 15px 10px;
    user-select: none;
  }

  &-title {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    max-width: 320px;
    margin: 0 auto 15px;
    text-align: center;
    user-select: none;
    @include respondTo(600px) {
      font-size: 14px;
      line-height: 20px;
      max-width: 230px;
      margin: 0 auto 10px;
    }
  }

  &-success {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
    color: #3b3b3b;
    padding: 30px 10px;
    user-select: none;
    @include respondTo(600px) {
      font-size: 14px;
      line-height: 20px;
      max-width: 230px;
      margin: 0 auto;
    }
  }
}
</style>
