import { snakeCase } from 'lodash'
import store from '@/store'
const snakeKeysObjectDeep = require('snakecase-keys')

export function base64ToFile (dataURI, filename) {
  if (!dataURI) return ''
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return new File([blob], `${filename}${mimeString.replace('image/', '.')}`, { type: mimeString })
}

export function toSnakeCase (data, type='object') {
  const payload = type === 'object' ? {} : []
  Object.entries(data).forEach(e => {
    const [key, value] = e
    if(type === 'object'){
      payload[snakeCase(key)] = value
    }else{
      payload.push(snakeKeysObjectDeep(value))
    }

  })
  return payload
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export function toCamelCaseRequestPayload (data) {
  const payload = {}
  Object.entries(data).forEach(e => {
    const [key, value] = e
    payload[snakeCase(key)] = value
  })
  return payload
}

export const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next()
    return
  }
  if (to.params.username) {
    next(`/${to.params.username}`)
  } else {
    next('/')
  }
}

export const isIOSDevice = () => {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod/i)
}
