export default {
  // footer
  xCommunications: 'xसंचार',
  xCommunicationsInvestorRelations: 'निवेशक सम्बन्ध',
  xCommunicationsMediaRequest: 'मीडिया अनुरोध',
  xCommunicationsOurPositionPrivacy: 'गोपनीयता पर हमारी स्थिति',
  xCommunicationsKnowledgeBase: 'ज्ञानधार',
  xCommunicationsOurPosition: 'साइबरबुलिंग पर हमारी स्थिति',
  premiumTools: 'प्रीमियम उपकरण',
  premiumToolsSayHolaLiveChatPlugin: 'SayHola लाइव चैट प्लगइन',
  premiumToolsSayHolaBlogPlugin: 'SayHola ब्लॉग प्लगइन',
  premiumToolsTogetherSayHola: 'एक साथ SayHola . द्वारा',
  premiumToolsLiveLeagueApp: 'लाइव लीग ऐप',
  programs: 'कार्यक्रमों',
  programsAdvertiseHola: 'होला के साथ विज्ञापन दें',
  programsFreelancerProgram: 'फ्रीलांसर कार्यक्रम',
  programsAffiliateRewardsProgram: 'संबद्ध पुरस्कार कार्यक्रम',
  terms: 'मामले',
  termsAdsPolicy: 'विज्ञापन नीति',
  termsPrivacyPolicy: 'गोपनीयता नीति',
  termsCommunityStandards: 'समुदाय मानकों',
  termsTermsUse: 'उपयोग की शर्तें',
  termsLoyaltyRewardsTerms: 'वफादारी पुरस्कार शर्तें',
  termOfService: 'सेवा की शर्तें',
  privacyPolicy: 'गोपनीयता नीति',
  // Home Page
  homeSlider: {
    haveAccount: 'क्या आपके पास पहले से एक खाता मौजूद है?',
    changeNumber: 'अपना नंबर बदलने की जरूरत है?',
    getStartedButton: 'अभी शुरू हो जाओ!',
    sliderPrevButton: 'पीछे',
    sliderNextButton: 'पढ़ना जारी रखें',
    slogan1: 'होला क्रॉस प्लेटफॉर्म चैटबॉट ब्रिज टेक्नोलॉजी प्लेटफॉर्म आपके उपयोगकर्ताओं को दीर्घकालिक ग्राहकों में बदलने में मदद करता है। चैटबॉट ब्रिज के साथ, आप किसी भी चैट ऐप (व्हाट्सएप, टेलीग्राम आदि) से क्लाइंट जेनरेट कर सकते हैं और उन सभी को एक डैशबोर्ड के तहत प्रबंधित कर सकते हैं।',
    slogan2: 'हमारे तकनीकी समाधानों में केवल आपके द्वारा चुनी गई और उपयोग की जाने वाली सुविधाओं का उपयोग करके \'खाद्य ऑर्डर\' से लेकर \'ग्राहक सहायता\' तक सभी प्रकार की सेवा के लिए \'टेम्प्लेट बनाने में आसान\' शामिल हैं।',
    slogan3: 'ग्राहक बातचीत को मैप करने के लिए आप बटन-आधारित चैटबॉट का उपयोग करके अपने टेम्पलेट बना सकते हैं। ग्राहकों द्वारा पूछे जाने वाले प्रश्नों के प्रकारों का अनुमान लगाकर, चैटबॉट को उचित रूप से उत्तर देने के लिए प्रोग्राम किया जाता है, जिससे ग्राहक अनुभव उपयोगकर्ता के अनुकूल और प्रामाणिक हो जाता है।',
    slogan4: 'हमारा डेमो देखें या आज ही लॉग इन करके और प्रीमियम ग्राहक बनकर शुरुआत करें',
    needMoreInfo: 'अधिक जानकारी चाहिए?',
    learnMore: 'और अधिक जानें'
  },
  signIn: 'साइन इन करें',
  recovery: 'स्वास्थ्य लाभ',
  otp_unconfirmed: 'ओटीपी की पुष्टि नहीं हुई है, फिर से कोशिश करें',
  otpDescription: 'आपको एक एसएमएस कोड भेजा गया है। कृपया नीचे कोड दर्ज करें।',
  otpEnterYourOtp: 'अपना ओटीपी कोड दर्ज करें',
  otpEnterYourPin: 'अपना पिन कोड दर्ज करें',
  verify_otp: 'पुष्टि करना',
  otpDontReceiveCode: 'कोड प्राप्त नहीं हुआ?',
  otpRequestSMSAgain: 'फिर से एसएमएस का अनुरोध करें',
  otpRequestNewCode: 'में एक नए कोड का अनुरोध करें',
  copyright: 'एक्स संचार। सर्वाधिकार सुरक्षित',
  enterPhoneNumber: 'कृपया अपना देश चुनें और अपना पूरा फोन नंबर दर्ज करें।',
  login: 'लॉग इन करें',
  validationSelectFile: 'फोटो चुनें और अपलोड करें',
  validationSameAs: 'पासवर्ड मेल नहीं खाते',
  validationMaxLengthField: 'फ़ील्ड वर्णों की अधिकतम संख्या - :गिनती',
  validationMinLengthField: 'फ़ील्ड वर्णों की न्यूनतम संख्या - :गिनती',
  validationRequired: 'इस फ़ील्ड को भरें',
  validationEmail: 'एक वैध ईमेल प्रविष्ट करें',
  validationUrl: 'एक मान्य यूआरएल दर्ज करें',
  needChangePhone: 'अपना नंबर बदलने की जरूरत है?',
  learnMore: 'और अधिक जानें',
  startUsingHola: '<br> सामाजिक स्थान देखने के लिए SayHola का उपयोग करना प्रारंभ करें',
  startUsingHolaFollow: 'अनुसरण करने के लिए SayHola का उपयोग करना प्रारंभ करें',
  socialSpace: 'सामाजिक स्थान',
  socialSpaceInvite: 'सोशल स्पेस आमंत्रण',
  socialSpaceInviteMessage: 'व्यापार, मशहूर हस्तियों, ब्लॉगर्स, प्रभावित करने वालों, समुदाय और व्यक्तियों के लिए टिप्पणियों और नकली समाचारों के बिना एक सुरक्षित और सुरक्षित, निजी स्थान',
  enterPhone: 'फ़ोन दर्ज करें',
  payNow: 'अब भुगतान करें',
  formCardName: 'कार्डधारक का नाम',
  formCardNumber: 'कार्ड संख्या',
  formExpDate: 'समाप्ति तिथि',
  formCvv: 'सीवीवी',
  errorEmptyField: 'यह फ़ील्ड आवश्यक है',
  paymentBtnPay: 'अब भुगतान करें',
  errorTitle: 'त्रुटि',
  error: 'क्षमा करें, कुछ गलत हो गया',
  errorEmailInvalid: 'एक मान्य ईमेल पता दर्ज करें',
  errorPhoneInvalid: 'अवैध फोन नंबर',
  errorStripe: 'कृपया सुनिश्चित करें कि आप सभी फ़ील्ड सही ढंग से भरते हैं',
  offer: 'प्रस्ताव',
  enterCode: 'कोड दर्ज करें',
  enterPin: 'पिन दर्ज करें',
  enterCardholderName: 'कार्डधारक का नाम दर्ज करें',
  logout: 'लॉग आउट',
  confirmation: 'पुष्टीकरण',
  thanksJoining: 'शामिल होने के लिए धन्यवाद',
  successText: 'अक्सर पूछे जाने वाले प्रश्न पर जाएं और जानें कि कई विशेष सुविधाओं का उपयोग कैसे करें। अतिरिक्त प्रश्नों के लिए समर्थन से संपर्क करें। आप क्या करना पसंद करेंगे?',
  downLoadApp: 'ऐप डाउनलोड करें',
  returnToHola: 'होला पर लौटें',
  addSponsor: 'प्रायोजक जोड़ें',
  videoAndFAQ: 'वीडियो और अक्सर पूछे जाने वाले प्रश्न\'s',
  pin_unconfirmed: 'सही पिन दर्ज करें!',
  selectPackage: 'पैकेज चुनें',
  offerExpired: 'विशेष पेशकश की समय सीमा समाप्त!',
  jobBrief: 'नौकरी संक्षिप्त',
  responsibilities: 'जिम्मेदारियों',
  requirements: 'आवश्यकताएं',
  currentJobPositions: 'वर्तमान नौकरी के अवसर',
  sendCampaign: 'अभियान भेजें',
  footerPrivateAppJobsPage: 'काम के अवसर',
  enterLatinLetter: 'इस फ़ील्ड में केवल अक्षरांकीय वर्ण, डैश और अंडरस्कोर शामिल हो सकते हैं [a-z0-9-_]',
  nicknameExist: 'उपनाम पहले से ही मौजूद है!',
  successCreateProfile: 'प्रोफ़ाइल सफलतापूर्वक बनाई गई!',
  downloadApp: 'ऐप डाउनलोड करें',
  faqAndVideo: 'पूछे जाने वाले प्रश्न और वीडियो',
  congratulations: 'बधाई हो',
  hosSubmitMessage: 'अपना आवेदन कैसे जमा करें:',
  profilePicture: 'प्रोफ़ाइल फोटो',
  profilePictureMessage: 'अपना सोशल स्पेस प्रोफ़ाइल चित्र जोड़ें, हम जानना चाहते हैं कि हम किसके साथ चैट कर रहे हैं।',
  chatProfilePictureMessage: 'अपना प्रोफ़ाइल चित्र जोड़ें, हम जानना चाहते हैं कि हम किसके साथ चैट कर रहे हैं।',
  firstName: 'पहला नाम',
  lastName: 'अंतिम नाम',
  nickname: 'उपनाम',
  location: 'स्थान (शहर, देश)',
  btnNext: 'अगला',
  btnFinish: 'खत्म हो',
  email: 'ईमेल',
  emailMessage: 'आपके ईमेल का उपयोग केवल पुनर्प्राप्ति के लिए किया जाएगा। अपना ईमेल दर्ज करके आप SayHola गोपनीयता नीति और नियम और शर्तें स्वीकार करते हैं। पुनर्प्राप्ति के लिए आपके ईमेल पर लिंक भेजे जाएंगे।',
  changesSaved: 'बदलाव सुरक्षित किया गया!',
  emptyProfilePictureField: 'कृपया अपना प्रोफ़ाइल चित्र अपलोड करें',
  register: 'रजिस्टर करें',
  resendOtpMessage: 'एक और एसएमएस भेजा गया।',
  loginByQRCodeMessage: 'स्मार्टफोन के माध्यम से लॉगिन करने के लिए क्यूआर कोड',
  authOption: 'कृपया लॉग इन करने के लिए एक विकल्प चुनें।',
  newRegistration: 'नया पंजीकरण',
  registrationOption: 'सेवाओं का उपयोग करने के लिए पंजीकरण के लिए एक ईमेल और उपयोगकर्ता नाम की आवश्यकता होती है। एक बार लॉग-इन करने के बाद आपके पास अपने फोन नंबर का उपयोग करके अन्य मैसेंजर ऐप्स के साथ सिंक करने का विकल्प होता है',
  loginWithApp: 'ऐप के साथ लॉगिन करें',
  loginWithAppOption: 'अपने फोन पर होला खोलें, ऐप के ऊपरी दाएं कोने में स्थित मेनू पर जाएं। क्यूआर कोड आइकन पर क्लिक करें। लॉगिन की पुष्टि करने के लिए अपने फोन को इस स्क्रीन पर इंगित करें।',
  recoveryOption: 'स्वास्थ्य लाभ',
  recoveryOptionText: 'रिकवरी के लिए हॉट-लिंक का उपयोग करके लॉगिन करने के लिए अपना पंजीकृत ईमेल दर्ज करें। अक्सर पूछे जाने वाले प्रश्न अनुभाग पर जाकर और जानें',
  enterEmailRecovery: 'पुनर्प्राप्ति के लिए अपना पंजीकृत ईमेल दर्ज करें:',
  startUsingSayHola: 'SayHola . का उपयोग करना प्रारंभ करें',
  formEmail: 'आपका ईमेल',
  notFoundUserLabel: 'इस ईमेल वाला उपयोगकर्ता नहीं मिला',
  successSendRecoveryEmailMessage: 'अपना ईमेल जांचें और निर्देशों का पालन करें!',
  chatBots: 'चैटबॉट्स',
  BdtlAgencyProgram: 'बीडीटीएल एजेंसी कार्यक्रम',
  agencyTerms: 'एजेंसी शर्तें',
  holaExtensions: 'होला एक्सटेंशन',
  holaBlog: 'होला ब्लॉग',
  authSlogan1: 'अब हम लैटिन अमेरिका में खाद्य और उत्पादों के लिए डिलीवरी ड्राइवर्स की भर्ती कर रहे हैं',
  authSlogan2: 'हम उच्चतम ट्रिप दरों का भुगतान करते हैं: $2 प्रति ट्रिप प्लस अतिरिक्त प्रति किलोमीटर',
  authSlogan3: 'डेस्पाक्सो ड्राइवरों से कोई कमीशन नहीं लेता है',
  authSlogan4: 'अपने खुद के बॉस बनें - सुपर-फास्ट भुगतान करें',
  authSlogan5: 'आज ही पंजीकृत करें!',
  loginByEmail: 'ईमेल द्वारा लॉग इन करें',
  registerByEmail: 'ईमेल द्वारा रजिस्टर करें',
  registerToday: 'आज ही पंजीकृत करें।'
}
