export default {
  // footer
  termOfService: '利用規約',
  privacyPolicy: '個人情報保護方針',
  xCommunications: 'xコミュニケーション',
  xCommunicationsInvestorRelations: '投資家向け広報活動',
  xCommunicationsMediaRequest: 'メディアリクエスト',
  xCommunicationsOurPositionPrivacy: 'プライバシーに関する私たちの立場',
  xCommunicationsKnowledgeBase: '知識ベース',
  xCommunicationsOurPosition: 'ネットいじめに関する私たちの立場',
  premiumTools: 'プレミアムツール',
  premiumToolsSayHolaLiveChatPlugin: 'sayHolaライブチャットプラグイン',
  premiumToolsSayHolaBlogPlugin: 'sayHolaブログプラグイン',
  premiumToolsTogetherSayHola: 'sayHolaで一緒に',
  premiumToolsLiveLeagueApp: 'ライブリーグアプリ',
  programs: 'プログラム',
  programsAdvertiseHola: 'Holaで宣伝する',
  programsFreelancerProgram: 'フリーランサープログラム',
  programsAffiliateRewardsProgram: 'アフィリエイトリワードプログラム',
  terms: '条項',
  termsAdsPolicy: '広告ポリシー',
  termsPrivacyPolicy: '個人情報保護方針',
  termsCommunityStandards: 'コミュニティ基準',
  termsTermsUse: '利用規約',
  termsLoyaltyRewardsTerms: 'ロイヤルティリワード規約',
  // Home Page
  homeSlider: {
    haveAccount: 'すでにアカウントをお持ちですか？',
    changeNumber: '番号を変更する必要がありますか?',
    getStartedButton: '今すぐ始めましょう!',
    sliderPrevButton: 'バック',
    sliderNextButton: '続きを読む',
    slogan1: 'Hola Cross Platformチャットボットブリッジテクノロジープラットフォームは、ユーザーを長期的な顧客に変えるのに役立ちます。 Chatbot Bridgeを使用すると、任意のチャットアプリ（WhatsApp、Telegramなど）からクライアントを生成し、それらすべてを1つのダッシュボードで管理できます。',
    slogan2: '当社の技術ソリューションには、選択して使用する機能のみを使用した「食品注文」から「カスタマーサポート」まで、すべてのサービスタイプの「テンプレートの作成が簡単」が含まれています。',
    slogan3: 'ボタンベースのチャットボットを使用してテンプレートを作成し、顧客との会話を計画することができます。チャットボットは、顧客が尋ねる可能性のある種類の質問を予測することにより、適切に応答するようにプログラムされており、それによって顧客体験をユーザーフレンドリーで本物にしています。',
    slogan4: 'デモを表示するか、ログインしてプレミアムカスタマーになることで今すぐ始めましょう',
    needMoreInfo: '詳細情報が必要ですか？',
    learnMore: 'もっと詳しく知る'
  },
  signIn: 'サインイン',
  recovery: '回復',
  otp_unconfirmed: 'Otp未確認、再試行',
  otpDescription: 'SMSコードが送信されました。以下にコードを入力してください。',
  otpEnterYourOtp: 'OTPコードを入力してください',
  otpEnterYourPin: 'PINコードを入力してください',
  verify_otp: '確認',
  otpDontReceiveCode: 'コードを受け取りませんでしたか？',
  otpRequestSMSAgain: 'もう一度SMSをリクエストする',
  otpRequestNewCode: 'で新しいコードをリクエストする',
  copyright: 'xCommunications。全著作権所有',
  enterPhoneNumber: '国を選択し、完全な電話番号を入力してください。',
  login: 'ログイン',
  validationSelectFile: '写真を選択してアップロードする',
  validationSameAs: 'パスワードが一致していません',
  validationMaxLengthField: 'フィールド文字の最大数 - :count',
  validationMinLengthField: 'フィールド文字の最小数 - :count',
  validationRequired: 'このフィールドに入力します',
  validationEmail: '有効なメールアドレスを入力してください',
  validationUrl: '有効なURLを入力してください',
  needChangePhone: '番号を変更する必要がありますか？',
  needMoreInfo: '詳細情報が必要ですか？',
  learnMore: 'もっと詳しく知る',
  startUsingHola: 'sayHolaを使用して<br>ソーシャルスペースを表示します',
  startUsingHolaFollow: 'フォローするためにsayHolaの使用を開始します',
  socialSpace: 'ソーシャルスペース',
  socialSpaceInvite: 'ソーシャルスペースへの招待',
  socialSpaceInviteMessage: 'コメントやフェイクニュースのない、ビジネス、有名人、ブロガー、インフルエンサー、コミュニティ、個人のための安全で安全なプライベートスペース',
  enterPhone: '電話を入力してください',
  payNow: '今払う',
  formCardName: 'クレジットカード名義人氏名',
  formCardNumber: 'カード番号',
  formExpDate: '有効期限',
  formCvv: 'CVV',
  errorEmptyField: 'この項目は必須です',
  paymentBtnPay: '今払う',
  errorTitle: 'エラー',
  error: 'すみません、問題が発生しました',
  errorEmailInvalid: '有効なメールアドレスを入力してください',
  errorPhoneInvalid: '無効な電話番号',
  errorStripe: 'すべてのフィールドに正しく入力していることを確認してください',
  offer: 'オファー',
  enterCode: 'コードを入力する',
  enterPin: 'ピンを入力してください',
  enterCardholderName: 'カード所有者名を入力してください',
  logout: 'ログアウト',
  confirmation: '確認',
  thanksJoining: '参加していただきありがとうございます',
  successText: 'FAQにアクセスして、多くの特別な機能の使用方法を学びます。追加の質問については、サポートにお問い合わせください。何をしたいですか？',
  downLoadApp: 'アプリをダウンロードする',
  returnToHola: 'ホラに戻る',
  addSponsor: 'スポンサーを追加',
  videoAndFAQ: 'ビデオとFAQ',
  pin_unconfirmed: '正しいピンを入力してください！',
  selectPackage: 'パッケージを選択',
  offerExpired: '特別オファーの期限が切れました！',
  jobBrief: '仕事の概要',
  responsibilities: '責任',
  requirements: '要件',
  currentJobPositions: '現在の仕事の機会',
  sendCampaign: 'キャンペーンを送信',
  footerPrivateAppJobsPage: '仕事の機会',
  enterLatinLetter: 'このフィールドには、英数字、ダッシュ、アンダースコアのみを含めることができます[a-z0-9-_]',
  nicknameExist: 'ニックネームはすでに存在します！',
  successCreateProfile: 'プロファイルが正常に作成されました。',
  downloadApp: 'アプリをダウンロードする',
  faqAndVideo: 'よくある質問とビデオ',
  congratulations: 'おめでとう',
  hosSubmitMessage: '申請書の提出方法：',
  profilePicture: 'プロフィールの写真',
  profilePictureMessage: 'ソーシャルスペースのプロフィール写真を追加してください。チャット相手を知りたいのですが。',
  chatProfilePictureMessage: 'あなたのプロフィール写真を追加してください、私たちは誰とチャットしているのか知りたいです。',
  firstName: 'ファーストネーム',
  lastName: '苗字',
  nickname: 'ニックネーム',
  location: '場所（都市、国）',
  btnNext: '次',
  btnFinish: '終了',
  email: 'Eメール',
  emailMessage: 'あなたの電子メールは回復のためだけに使われます。メールアドレスを入力すると、sayHolaのプライバシーポリシーと利用規約に同意したことになります。リンクは回復のためにあなたの電子メールに送られます。',
  changesSaved: '変更が保存されました！',
  emptyProfilePictureField: 'プロフィール写真をアップロードしてください',
  register: '登録',
  resendOtpMessage: 'もう1つのSMSが送信されました。',
  loginByQRCodeMessage: 'スマートフォン経由でログインするためのQRコード',
  authOption: 'ログインするオプションを選択してください。',
  newRegistration: '新規登録',
  registrationOption: '登録には、サービスを使用するための電子メールとユーザー名が必要です。ログインすると、電話番号を使用して他のメッセンジャーアプリと同期することができます',
  loginWithApp: 'アプリでログイン',
  loginWithAppOption: '携帯電話でHolaを開き、アプリの右上隅にあるメニューに移動します。 QRコードアイコンをクリックします。この画面に携帯電話を向けて、ログインを確認します。',
  recoveryOption: '回復',
  recoveryOptionText: '回復のためにホットリンクを使用してログインするために登録された電子メールを入力してください。 FAQセクションにアクセスして詳細をご覧ください',
  enterEmailRecovery: '登録したメールアドレスを入力して復旧します。',
  startUsingSayHola: 'sayHolaの使用を開始',
  formEmail: 'あなたのメール',
  notFoundUserLabel: 'このメールアドレスのユーザーが見つかりません',
  successSendRecoveryEmailMessage: 'あなたの電子メールをチェックして、指示に従ってください！',
  chatBots: 'チャットボット',
  BdtlAgencyProgram: 'BDTLエージェンシープログラム',
  agencyTerms: '代理店規約',
  holaExtensions: 'はいはい拡張',
  holaBlog: 'はいはいブログ',
  authSlogan1: 'ラテンアメリカでの食品および製品の配送ドライバーを募集しています',
  authSlogan2: '私たちは最高の旅行料金を支払います: 1 回の旅行につき 2 ドルと 1 km あたりの追加料金',
  authSlogan3: 'Despaxo はドライバーからコミッションを受け取りません',
  authSlogan4: 'あなた自身のボスになりましょう - 超高速で支払いを受けましょう',
  authSlogan5: '今日登録する！',
  loginByEmail: 'メールでログイン',
  registerByEmail: 'メールで登録',
  registerToday: '今日登録する。'
}
