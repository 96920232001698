import api from '@/api/driver'

const state = {
  currentItem: null,
  loading: false
}

const getters = {
  currentItem: state => state.currentItem,
  loading: state => state.loading,
}

const actions = {
  async fetchItem ({ commit }) {
    try {
      commit('setLoading', true)

      const result = await api.fetchItem()
      commit('setItem', result)
      return result
    } catch (e) {
      throw e.response
    }finally {
      commit('setLoading', false)
    }
  },
  async create ({ commit }, payload) {
    try {
      commit('setLoading', true)

      const result = await api.create(payload)
      commit('setItem', result)
      return result
    } catch (e) {
      throw e.response
    }finally {
      commit('setLoading', false)
    }
  },
  async update ({ commit }, payload) {
    try {
      commit('setLoading', true)

      const result = await api.update(payload)
      commit('setItem', result)
      return result
    } catch (e) {
      throw e.response
    }finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  setItem (state, data) {
    state.currentItem = data
  },
  setLoading (state, status) {
    state.loading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
