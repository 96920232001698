<template>
  <the-app-wrapper>
    <v-app id="app" class="app">
      <div class="app-container">
        <!--        <AppBanner />-->
        <div class="wrapper">
          <div class="wrapper-top">
            <AppHeader/>
            <div class="wrapper-content">
              <router-view/>
            </div>
          </div>
          <div class="wrapper-bottom">
            <AppFooter/>
          </div>
        </div>
      </div>
      <LoginByCode/>
      <SignIn/>
    </v-app>
  </the-app-wrapper>
</template>

<!--// global styles-->
<style lang="scss" src="./assets/scss/main.scss"></style>

<script>
import AppHeader from '@/components/features/AppHeader.vue'
import AppFooter from '@/components/features/AppFooter.vue'
import AppBanner from '@/components/features/AppBanner.vue'
import LoginByCode from '@/components/modals/LoginByCode.vue'
import SignIn from '@/components/modals/SignIn.vue'
import TheAppWrapper from "@/components/features/TheAppWrapper.vue";
import {mapActions} from "vuex";
import {clearTokens} from '@/helpers/tokens'

export default {
  components: {
    AppHeader,
    AppFooter,
    AppBanner,
    LoginByCode,
    SignIn,
    TheAppWrapper
  },
  mounted() {
    if (!!localStorage.getItem('accessToken')) {
      this.getMyProfile()
        .catch(() => {
          clearTokens()
        })
    }
  },
  methods: {
    ...mapActions({
      getMyProfile: 'auth/getProfile',
      fetchDriver: 'driver/fetchItem',
    }),
  }
}
</script>
