import apiReq from './request'

const BASE_URL = process.env.VUE_APP_ROOT_API

export default {
  async create (data) {
    const response = await apiReq.post(`${BASE_URL}/chatbots/driver${data.sponsorHolaId ? '?sponsor_hola_id='+data.sponsorHolaId : ''}`, data)
    return response.data.data
  },
  async update ({payload, query}) {
    const response = await apiReq.patch(`${BASE_URL}/chatbots/driver?driver_id=${query.driverId}`, payload)
    return response.data.data
  },
  async fetchItem () {
    const response = await apiReq.get(`${BASE_URL}/chatbots/driver`)
    return response.data.data
  }
}
