import apiReq from './request'

const BASE_URL = process.env.VUE_APP_ROOT_API

export default {
  async createContactInfo (data) {
    const response = await apiReq.post(`${BASE_URL}/user/user/contact-info`, data)
    return response.data.data
  },
  async fetchCountries () {
    const response = await apiReq.get(`${BASE_URL}/user/info/countries`)
    return response.data.data
  },
  async fetchUserInfo () {
    const response = await apiReq.get(`${BASE_URL}/auth/user/info`)
    return response.data.data
  }
}
