import apiReq from './request'

const BASE_URL = process.env.VUE_APP_ROOT_API
export const REFRESH_TOKEN_URL = `${BASE_URL}/refresh_token`

export default {
  logout (data) {
    return apiReq.post(`${BASE_URL}/auth/user/logout`, data)
  },
  authByCode (data) {
    return apiReq.post(`${BASE_URL}/auth/user/qr-login?fingerprint=${data.fingerprint}`, data.data)
  },
  sendQRCode (data) {
    return apiReq.post(`${BASE_URL}/auth/user/qr-code`, data)
  },
  async confirmEmailOtp(payload) {
    const response = await apiReq.post(`${BASE_URL}/auth/user/email-otp`, payload);
    return response.data.data
  },
  signIn (data) {
    return apiReq.post(`${BASE_URL}/auth/v4BPx8kxPUM5utBksHOg`, data, {headers: {}})
  },
  getProfile () {
    return apiReq(`${BASE_URL}/user/user/my_profile`)
  },
  async updateProfile (data) {
    const response = await apiReq.patch(`${BASE_URL}/user/user/my_profile`, data)
    return response.data.data
  },
  async createDriver (data) {
    const response = await apiReq.patch(`${BASE_URL}/user/user/my_profile`, data)
    return response.data.data
  },
  async updatePhone (data) {
    const response = await apiReq.post(`${BASE_URL}/auth/phone/update-phone`, data)
    return response.data.data
  }
}
