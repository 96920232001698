export default {
  // footer
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Investor Relations',
  xCommunicationsMediaRequest: 'Media Request',
  xCommunicationsOurPositionPrivacy: 'Our Position on Privacy',
  xCommunicationsKnowledgeBase: 'Knowledge Base',
  xCommunicationsOurPosition: 'Our Position on Cyberbullying',
  premiumTools: 'Premium Tools',
  premiumToolsSayHolaLiveChatPlugin: 'sayHola Live Chat plugin',
  premiumToolsSayHolaBlogPlugin: 'sayHola Blog plugin',
  premiumToolsTogetherSayHola: 'together by sayHola',
  premiumToolsLiveLeagueApp: 'Live League App',
  programs: 'Programs',
  programsAdvertiseHola: 'Advertise with Hola',
  programsFreelancerProgram: 'Freelancer Program',
  programsAffiliateRewardsProgram: 'Affiliate Rewards Program',
  terms: 'Terms',
  termsAdsPolicy: 'Ads Policy',
  termsPrivacyPolicy: 'Privacy Policy',
  termsCommunityStandards: 'Community Standards',
  termsTermsUse: 'Terms of Use',
  termsLoyaltyRewardsTerms: 'Loyalty Rewards Terms',
  termOfService: 'Term of Services',
  privacyPolicy: 'Privacy Policy',
  // Home Page
  homeSlider: {
    haveAccount: 'Already have an account?',
    changeNumber: 'Need to change your number?',
    getStartedButton: 'Get started now!',
    sliderPrevButton: 'Back',
    sliderNextButton: 'Continue to read',
    slogan1: 'Hola Cross Platform Chatbot Bridge technology platform helps convert your users into long term customers. With Chatbot Bridge, you can generate clients from any chat app (WhatsApp, Telegram etc)  and manage them all under one dashboard.',
    slogan2: 'Our Technological Solutions include ‘easy to build templates’ for all service types, from ‘food orders’ to ‘customer support’ using only features you select and use. ',
    slogan3: 'You can build your templates using Button-based Chatbots to map out customer conversations. By anticipating the kinds of questions that customers are likely to ask, the chatbot is programmed to respond appropriately, thereby making the customer experience user friendly and authentic.',
    slogan4: 'View our demo or get started today by logging in and becoming a Premium customer',
    needMoreInfo: 'Need more info?',
    learnMore: 'Learn More'
  },
  signIn: 'Sign in',
  recovery: 'Recovery',
  otp_unconfirmed: 'Otp unconfirmed, try again',
  otpDescription: 'An sms code has been sent to you. Please enter code below.',
  otpEnterYourOtp: 'Enter your otp code',
  otpEnterYourPin: 'Enter your pin code',
  verify_otp: 'Confirm',
  otpDontReceiveCode: "Didn't receive the code?",
  otpRequestSMSAgain: 'Request SMS again',
  otpRequestNewCode: 'Request a new code in',
  copyright: 'xCommunications. All rights reserved',
  login: 'Login',
  validationSelectFile: 'Select and upload photo',
  validationSameAs: 'Passwords do not match',
  validationMaxLengthField: 'Maximum number of field characters - :count',
  validationMinLengthField: 'Minimum number of field characters - :count',
  validationRequired: 'Fill out this field',
  validationEmail: 'Enter a valid email',
  validationUrl: 'Enter a valid url',
  needChangePhone: 'Need to change your number?',
  learnMore: 'Learn more',
  startUsingHola: 'Start using sayHola to view the <br> Social Space',
  startUsingHolaFollow: 'Start using sayHola to Follow',
  socialSpace: 'social space',
  socialSpaceInvite: 'Social Space invite',
  socialSpaceInviteMessage: 'a safe and secure, private space for business, celebrities, bloggers, influencers, community, and individuals without comments and fake news',
  enterPhone: 'Enter phone',
  payNow: 'Pay Now',
  formCardName: 'Cardholder name',
  formCardNumber: 'Card number',
  formExpDate: 'Expiry date',
  formCvv: 'CVV',
  errorEmptyField: 'This field is required',
  paymentBtnPay: 'Pay now',
  errorTitle: 'Error',
  error: 'Sorry, something went wrong',
  errorEmailInvalid: 'Enter a valid email address',
  errorPhoneInvalid: 'Invalid phone number',
  errorStripe: 'Please make sure that you fill in all the fields correctly',
  offer: 'Offer',
  enterCode: 'Enter code',
  enterPin: 'Enter pin',
  enterCardholderName: 'Enter cardholder name',
  logout: 'Logout',
  confirmation: 'Confirmation',
  thanksJoining: 'Thank you for joining',
  successText: 'Visit FAQ and learn how to use the many special features. Contact support for additional questions. What would you like to do?',
  downLoadApp: 'Download App',
  returnToHola: 'Return to Hola',
  addSponsor: 'Add Sponsor',
  videoAndFAQ: 'Videos & FAQ\'s',
  pin_unconfirmed: 'Enter the correct pin!',
  selectPackage: 'Select package',
  offerExpired: 'Special offer expired!',
  jobBrief: 'Job brief',
  responsibilities: 'Responsibilities',
  requirements: 'Requirements',
  currentJobPositions: 'Current Job Opportunities',
  sendCampaign: 'Send Campaign',
  footerPrivateAppJobsPage: 'Work Opportunities',
  enterLatinLetter: 'This field can only contain alphanumeric characters, dash and underscore [a-z0-9-_]',
  nicknameExist: 'Nickname already exists!',
  successCreateProfile: 'Profile created successfully!',
  downloadApp: 'download the app',
  faqAndVideo: 'faq and video',
  congratulations: 'Congratulations',
  hosSubmitMessage: 'How to submit your application:',
  profilePicture: 'Profile Picture',
  profilePictureMessage: 'Add your Social Space profile picture, we want to know who we are chatting with.',
  chatProfilePictureMessage: 'Add your profile picture, we want to know who we are chatting with.',
  firstName: 'First Name',
  lastName: 'Last Name',
  nickname: 'Nickname',
  location: 'Location (city, country)',
  btnNext: 'Next',
  btnFinish: 'Finish',
  email: 'Email',
  emailMessage: 'Your email will be used for recovery only. By entering your email you accept sayHola privacy policy and terms and conditions. Links will be sent to your email for recovery.',
  changesSaved: 'Changes saved!',
  emptyProfilePictureField: 'Please upload your profile picture',
  register: 'Register',
  resendOtpMessage: 'One more SMS was sent.',
  loginByQRCodeMessage: 'QR code for login via smartphone',
  newRegistration: 'new registration',
  registrationOption: 'Registration requires an email and username to use the services. Once logged-in you have the choice to sync with other messenger Apps using your phone number',
  loginWithApp: 'login with app',
  loginWithAppOption: 'Open Hola on your phone, go to the menu located in top right hand corner of the App. Click the QR code icon. Point your phone at this screen to confirm login.',
  recoveryOption: 'recovery',
  recoveryOptionText: 'For Recovery Enter your registered email to login using Hot-link. Learn more by visiting FAQ section',
  enterEmailRecovery: 'Enter your registered email to recovery:',
  authOption: 'Please select an option to Login',
  startUsingSayHola: 'Start Using sayHola',
  formEmail: 'Your email',
  notFoundUserLabel: 'User with this email not found',
  successSendRecoveryEmailMessage: 'Check your email and follow the instructions!',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'BDTL Agency Program',
  agencyTerms: 'Agency Terms',
  holaExtensions: 'Hola Extension',
  holaBlog: 'Hola Blog',
  authSlogan1: 'We are now recruiting Delivery Drivers for Food and Products in Latin America',
  authSlogan2: 'We pay the highest trip rates: $2 per trip plus extra per KM',
  authSlogan3: 'Despaxo takes no Commissions from Drivers',
  authSlogan4: 'Be your Own Boss - Get Paid Super-Fast',
  authSlogan5: 'Register Today!',
  loginByEmail: 'Login by email',
  registerByEmail: 'Register by email',
  registerToday: 'Register Today.',
  fieldFirstNameLabel: 'First name',
  fieldLastNameLabel: 'Last name',
  fieldEmailLabel: 'Email',
  fieldNicknameLabel: 'Nickname',
  emailWarning: "Your email will be used for recovery only. By entering your email you accept sayHola privacy policy and terms and conditions. Links will be sent to your email for recovery.",
  stepYourInfo: "Your Info",
  stepLicense: "License",
  stepIdentification: "Identification",
  stepVehicleInfo: "Vehicle Info",
  stepPoliceReport: "Police Report",
  stepAcceptance: "Acceptance",
  fieldBirthLabel: "Date of birth",
  uploadPhoto: 'Upload photo',
  changeImage: 'Change image',
  orDragDrop: 'or just Drag and Drop',
  send: 'Send',
  cancel: 'Cancel',
  save: 'Save',
  done: 'Done',
  continue: 'Continue',
  fieldProfilePhotoLabel: 'Profile photo',
  fieldLicenseNumberLabel: "License number",
  fieldPhotoFrontLabel: "Photo Front",
  fieldPhotoBackLabel: "Photo Back",
  fieldIdConfirmationLabel: "Id confirmation",
  fieldLicensePlateNumberLabel: "License plate number",
  fieldYearVehicleLabel: "Year of Vehicle",
  idConfirmHint: "Photo with your drivers license",
  photoVehicleLicensePlate: "Photo of your Vehicle with license plate",
  photoVehicleRegistrationCardFront: "Photo of Vehicle registration card front",
  photoVehicleRegistrationCardBack: "Photo of Vehicle registration card back",
  iAgreeWith: "I agree to the ",
  termsConditions: "terms and conditions",
  fieldSignatureLabel: "Signature",
  fieldCountryDrivingLabel: "Enter country driving for",
  despaxoDeliveryPolicySlogan1: 'DESPAXO DELIVERY POLICY',
  despaxoDeliveryPolicySlogan2: 'This Delivery Services Agreement (the “Agreement”) is made as of today’s current date, which is logged and time stamped upon the acceptance of the Agreement, by and between xCommunications, LLC., a St Kitts and Nevis Company, owner of the Despaxo Platform  (“Company”), and the Independent Contractor of whom is reviewing and accepting said Agreement.  (“Contractor”). ',
  despaxoDeliveryPolicySlogan3: 'RECITALS ',
  despaxoDeliveryPolicySlogan4: 'A. The Company provides the opportunity to connect Contractors, Merchants, and Consumers. Consumer’s use the Company’s platform to place orders with Merchants.  The Company’s platform notifies Contractor of the opportunity to provide a service to the consumer.  The Company is not a merchant, restaurant, food delivery service, or food preparation business.  The Company is a platform that acts as a marketplace connecting and assisting in the facilitation of delivery services from the Merchant to the Consumer via Contractor.  ',
  despaxoDeliveryPolicySlogan5: 'B. Contractor is a legally licensed and insured, independent provider of delivery services. Contractor is in lawful possession of and legally permitted to operate all equipment, including, but not limited to, wireless smartphone and vehicle and/or bicycle, necessary to meet general industry standards (“General Industry Standard”) and consumer expectations (“Consumer Expectations”) of completing delivery opportunities (“Delivery Opportunity”) in the most efficient method (“Most Efficient Method”) (definitions below).  Contractor understands and agrees that Contractor is NOT an employee of the Company.  Contractor understands that there is no guarantee of Delivery Opportunities available, and Contractor is free to select the times to be available on the platform to receive Delivery Opportunities that Contractor may or may not choose to accept.',
  despaxoDeliveryPolicySlogan6: 'In consideration of the above recitals and mutual assurances between the Company and Contractor below, the Company and Contractor agree to the following: ',
  despaxoDeliveryPolicySlogan7: '1. Delivery Services.  During the term of this Agreement, Contractor will have the opportunity to provide delivery services from Merchants to consumers using the Company’s platform (the “Services”). Depending on the nature of the delivery and the requirements of the Company and/or consumers and Merchants, such Services may be carried out by means of any combination of (a) foot travel by Contractor, (b) use of Contractor’s personal bicycle, (c) use of Contractor’s personal vehicle, or (d) use of Contractor’s personal mobile device, tablet, or computer.  Contractor acknowledges that the Company has the discretion as to which, if any, Delivery Opportunities has to offer, and Contractor has discretion as to which, if any, Delivery Opportunities to accept.  ',
  despaxoDeliveryPolicySlogan8: '2. Payment for Services.  As consideration for the Services to be provided by Contractor and other obligations, the Company has engineered, through the Despaxo platform for the Contractor to be paid directly upon performance of duties and at a rate clearly noted on the Despaxo platform (the “Compensation Rate”).  Contractor Compensation Rate is a combination of a set rate, plus an incremental increase based off of KM distance from the merchant to consumer and may differ in amount accounting for variables of individual Delivery Opportunities.',
  despaxoDeliveryPolicySlogan9: 'Opportunities available to Contractor to accept, and consumer payment behavior.  Unless otherwise notified by the Company in writing or except as provided herein, Contractor will receive payment for Services at the current Compensation Rate by means designated by the Company.  Payments will be deposited directly into Contractor wallet accounts located at myxpay.net on an immediate basis or as soon as the delivery has been executed. ',
  despaxoDeliveryPolicySlogan10: '3. Expenses.  Contractor shall not be authorized to incur on behalf of the Company any expenses and will be responsible for all expenses incurred while performing the Services, including without limitation gasoline, insurance coverage and maintenance for Contractor’s personal vehicle and/or bicycle, cellular device and data costs, unless otherwise agreed to in writing by the Company.  ',
  despaxoDeliveryPolicySlogan11: '4. Term and Termination.  This agreement is effective on the date executed by the Contractor and expires upon termination of either party with no written notice necessary.',
  despaxoDeliveryPolicySlogan12: '(a) General Industry Standards.  Both parties agree that while Contractor is an independent provider of delivery services, there are certain standards that are the norm within the delivery services industry.  These norms include, but may not be limited to Contractor’s interaction with consumers and Merchants, safe handling of food and non-food items requested by consumer through the platform, privacy of consumer information, interaction with the Company’s support representatives, fulfilling requested and/or accepted Delivery Opportunities and delivery opportunity periods, responsiveness to the Company support personnel regarding accepted Delivery Opportunities and delivery opportunity periods, and completing accepted Delivery Opportunities in the Most Efficient Manner; (1) once a Delivery Opportunity is accepted, completing the accepted Service is prioritized by Contractor over other jobs or personal interests, and (2) Consumer Expectations of the Services being provided are met to the fullest extent possible, taking into consideration Contractor’s responsibility for determining the method, details and means of performing the Services. ',
  despaxoDeliveryPolicySlogan13: '(b) Consumer Expectations.  Both parties agree that while Contractor is an independent provider of delivery services, the Contractor is performing Services on behalf of the consumer and Merchant, therefore either party has the right to hold Contractor providing Services responsible for certain norms surrounding the Services being provided.   These norms include, but may not be limited to Contractor providing Services in a timely (shortest method available), safe and professional manner consistent with General Industry Standard, Contractor attempting to provide the Services as close to “door to door” as possible taking into consideration logistical and legal limitations, or providing Services from supplier to a mutually agreed upon location with the consumer, food and/or non-food items are delivered to consumer representative of the condition provided by the supplier, food and/or non-food items are representative of the order the consumer placed with the supplier on the Company platform, and consumer’s personal information and property are respected by Contractor during and after Services are provided.',
  despaxoDeliveryPolicySlogan14: '5. Independent Contractor.  Contractor’s relationship with the Company will be that of an independent contractor / Professional Services and not that of an employee. ',
  despaxoDeliveryPolicySlogan15: '(a) Method of Provision of Services.  Contractor shall be solely responsible for determining the method, details and means of performing the Services.  Contractor agrees that all legal requirements necessary to perform the Services of this Agreement are met and will be maintained as required by law.  Contractor is not required to personally complete Services and both parties agree that Contractor can engage with subcontractors or personnel to complete some or all aspects of Services, subject to the requirements of this Section 5(a).',
  despaxoDeliveryPolicySlogan16: '(i) Use of subcontractors, employees, personnel or other representatives of Contractor and in the manner that the subcontractors, employees, personnel or other representatives are utilized is solely up to Contractor at Contractor’s own risk and expense.  Contractor shall be responsible for direction and control of any subcontractors, employees, personnel or other representatives it chooses to utilize in the performance and fulfillment of Services. ',
  despaxoDeliveryPolicySlogan17: '(ii) Contractor is fully and solely responsible for all payment to subcontractors, employees, personnel or other representatives due in regards to services and work performed on behalf of Contractor or upon Contractor’s request pertaining to this Agreement; including wages, benefits, expenses, required tax withholdings, unemployment contributions, and any other reimbursements or payments owed based on completed services by Contractor and all subcontractors, employees, personnel or other representatives engaged by Contractor in Services included within this Agreement.  Both parties agree that the Company shall have no responsibility for any wages, benefits, expenses, required tax withholdings, unemployment contributions, or other payroll taxes relating to Contractor or Contractor’s subcontractors, employees, personnel or other representatives.  Neither Contractor or Contractor’s subcontractors, employees, personnel or other representatives are eligible for any benefits of any kind from the Company.  ',
  despaxoDeliveryPolicySlogan18: '(iii) Contractor is fully and solely responsible for the performance and expenses of its business, including the method of sharing Delivery Opportunities with subcontractors, employees, personnel or other representatives, compliance with all federal, state and municipal laws, rules, ordinances and other requirements by governing and insuring authorities relating to the legality and competency of any subcontractors, employees, personnel or other representatives performing services on behalf of Contractor under this Agreement. This includes meeting and maintaining equipment standards as required by this Agreement as well as any criminal and/or motor vehicle record verifications.  Contractor understands that subcontractors, employees, personnel or other representatives are working on behalf of Contractor, and any material breaches of this Agreement by said subcontractors, employees, personnel or other representatives performing services on behalf of Contractor will be treated as if Contractor was personally performing the Services and follow the notification and/or termination of Agreement for cause standards agreed to within this Agreement. ',
  despaxoDeliveryPolicySlogan19: '(iv) Contractor  shall wear or display the Company’s or a Company Sponsor’s brand or logo  whether on apparel, equipment or other similar  merchandise where and when instructed. Company may provide apparel, Company will make available opportunity for Contractor to purchase equipment or merchandise to better perform the tasks associated with this Agreement.  ',
  despaxoDeliveryPolicySlogan20: '(v) Contractor agrees to not create or design any equipment or apparel with Company branding or likeness of Company branding that could be inferred as Company logo or branding for personal use and/or gain, or for use and/or gain by Contractor’s subcontractors, employees, personnel or other representatives without the express written consent of the Company. ',
  despaxoDeliveryPolicySlogan21: '(vi) Due to the inherent safety risks of having additional passengers in/on a vehicle during Services performed, Contractor is solely responsible for guaranteeing that only Contractor and/or Contractor’s subcontractors, employees, personnel or other representatives are present during the time of providing Services.  If Contractor is utilizing subcontractors, employees, personnel or other representatives to complete all or certain aspects of the Services, Contractor’s subcontractors, employees, personnel or other representatives must satisfy all terms of this Agreement as well as current Company screening and background check requirements.  Contractor agrees that all aspects of this Agreement will be included and enforceable by Contractor’s own written agreement between Contractor and subcontractor, employee, personnel or other representative.  A copy of said written agreement shall be provided to the Company no less than seven (7) days prior to Contractor utilizing services of subcontractor, employee, personnel or other representative.  Both parties acknowledge that this requirement is to ensure Contractor’s compliance with this Agreement and all terms included in this Agreement. ',
  despaxoDeliveryPolicySlogan22: '(b) No Authority to Bind Company.  Contractor acknowledges and agrees that Contractor has no authority to enter into contracts that bind the Company or create obligations on the part of the Company without the prior written authorization of the Company. ',
  despaxoDeliveryPolicySlogan23: '(c) No Benefits.  Contractor acknowledges and agrees that Contractor shall not be eligible for any Company employee benefits and, to the extent Contractor otherwise would be eligible for any Company employee benefits but for the express terms of this Agreement, Contractor (on behalf of itself and its employees) hereby expressly declines to participate in such Company employee benefits.  ',
  despaxoDeliveryPolicySlogan24: '(d) Withholding; Indemnification.  Contractor shall have full responsibility for applicable withholding taxes for all compensation paid to Contractor under this Agreement, and for compliance with all applicable labor and employment requirements with respect to Contractor’s self-employment, sole proprietorship or other form of business organization.  Contractor agrees to indemnify, defend and hold the Company harmless from any liability, or assessment of, any claims or penalties with respect to such withholding taxes, labor or employment requirements, including any liability for, or assessment of, withholding taxes imposed on the Company by the relevant taxing authorities with respect to any compensation paid to the Contractor. ',
  despaxoDeliveryPolicySlogan25: '(e) Uniforms and Equipment.  Contractor is required to purchase, lease or rent any products, equipment or services from the Company as a condition of providing Services or entering into this Agreement. The Company requires Contractor to wear a uniform, other clothing or equipment of any type bearing the Company’s name, logo or colors or that of a Company Sponsor.  ',
  despaxoDeliveryPolicySlogan26: '(f) Multiple Platforms.  Contractor has the right to perform services for others and to be available to the general public as a separately established business.  Both Contractor and the Company recognize that they are or may be engaged in similar arrangements with other and nothing in this Agreement shall prevent either party from doing business with others.  The Company does not have the right to restrict Contractor from offering Services with other entities, including businesses that may directly compete with the Company.  Provided Contractor can meet General Industry Standards, Customer Expectations, and the material terms of this Agreement, nothing in this Agreement shall prohibit Contractor from completing Services while under Agreement with the Company. ',
  despaxoDeliveryPolicySlogan27: '<strong>Acknowledgement:</strong>  Contractor understands and agrees that if Contractor was an employee of the Company, Contractor would be prohibited from working for or providing services to competitors and the Company would be able to control the dates, times and location Contractor provided Services.  Contractor’s decision to be an independent contractor is made freely, knowingly and with knowledge of the benefits that Contractor derives from being free from control of any employer, including the ability to choose, when, where, how, and whether to provide Services, as well as to provide Services to competing companies. ',
  despaxoDeliveryPolicySlogan28: '6. Contractor’s Acceptance of Providing Services.  During the term of this Agreement, the Company may notify Contractor of the opportunity to complete Services involving Merchants and consumers facilitated through the platform under the Company’s brand name.  With respect to each Delivery Opportunity accepted by Contractor, Contractor agrees to complete any of the necessary steps to fully provide the agreed upon Service, including the retrieval of the order from the supplier, and delivery of the order to consumers in a timely (shortest method available), safe and professional manner consistent with Customer Expectations and General Industry Standards.  Contractor understands and agrees that the parameters of each Delivery Opportunity are established by the consumer and/or Merchant, not the Company, and represent the end result desired, not the mean, method or manner by which Contractor is to accomplish the result. ',
  despaxoDeliveryPolicySlogan29: '7. Confidential Information.  Contractor agrees, at all times during the term of this Agreement and thereafter, to hold in strictest confidence, and not to use except for the benefit of the Company to the extent necessary to perform its obligations hereunder, and to not disclose to any person, firm, corporation or other entity, without written authorization from the Company in each instance, any Confidential Information (as defined below) that a Contractor obtains, accesses or creates during the term of this Agreement, whether or not during working hours, until such Confidential Information becomes publicly and widely known and made generally available through no wrongful act of Contractor.  Contractor further agrees not to make copies of such Confidential Information except as authorized by the Company.  “Confidential Information” means information and physical material not generally known or available outside the Company and information and physical material entrusted to the Company in confidence by third parties.  Confidential Information includes, without limitation: (i) company inventions; (ii) technical data, trade secrets, know-how, research, product or service ideas or plans, software codes and designs, developments, inventions, laboratory notebooks, processes, formulas, techniques, lists of, or information relating to, Merchants and customers, pricing methodologies, cost data, market share data, budgets or other business information disclosed to Contractor by the Company either directly or indirectly, whether in writing, electronically, orally, or by observation. ',
  despaxoDeliveryPolicySlogan30: '8. Representations, Warranties and Covenants of Contractor.',
  despaxoDeliveryPolicySlogan31: '(a) If performing any portion of the Services via use of its personal vehicle (whether automotive or non-automotive, such as a bicycle), Contractor represents and warrants that Contractor has all appropriate licenses, approvals and authority to use the personal vehicle and provide the Services.  If performing any portion of the Services via use of its personal vehicle that is automotive (such as a moped, automobile or truck), Contractor represents and warrants that (i) Contractor has valid  driver license in the country or area where Contractor will perform the Services, and (ii) Contractor is authorised to operate a motor vehicle.  The Company holds the right to verify validity of such license and request proof of validity to be readily supplied by Contractor upon request.',
  despaxoDeliveryPolicySlogan32: '(b) If performing any portion of the Services via use of its personal vehicle, Contractor represents and warrants that Contractor has obtained, and Contractor covenants and agrees to maintain in force at all times during the term of this Agreement, (i) insurance on the personal vehicle that meets or exceeds the minimum local liability insurance required by law in the area where Contractor will perform the Services; and (ii) adequate insurance to protect Contractor from (A) claims for damages because of bodily injury, sickness, disease or death that arise out of any negligent act or omission of Contractor and (B) claims for damages because of injury to or destruction of tangible or intangible property, including loss of use resulting therefrom, that arise out of any negligent act or omission of Contractor.  If requested by the Company, Contractor shall take steps for the Company to be named as additional insured or additional loss payee, as appropriate, on Contractor’s motor vehicle insurance policy or other insurance policies applicable to the Services and such insurance policies shall contain such additional endorsements as shall be reasonably required by the Company.  The Company holds the right to request proof of such insurance by Contractor and Contractor shall have valid insurance documentation readily available to be provided to the Company upon request. ',
  despaxoDeliveryPolicySlogan33: '(c) Contractor covenants and agrees that, in the course of providing the Services, Contractor will at all times comply with all applicable laws, including without limitation all traffic laws and provisions of the motor vehicle code, including such laws and provisions applicable to the operation of a non-automotive vehicle such as a bicycle, and (if applicable) otherwise operate Contractor’s personal vehicle in a safe and reasonable manner.  Contractor agrees that the Company’s platform does not require the use of a mobile phone while operating a motor vehicle and/or bicycle.  ',
  despaxoDeliveryPolicySlogan34: '(d) Contractor represents and warrants that it is not under any pre-existing obligation in conflict or in any way inconsistent with the provisions of this Agreement.  Contractor represents and warrants that Contractor’s performance of all the terms of the Agreement will not breach any agreement to keep in confidence proprietary information acquired by Contractor in confidence or it trust prior to commencement of this Agreement.  ',
  despaxoDeliveryPolicySlogan35: '(e) Indemnification.  Contractor will indemnify and hold harmless Company from and against all claims, damages, losses and expenses, including court costs and reasonable attorneys’ fees, arising out of or resulting from, and, at Company’s option, Contractor will defend Company against any action by a third party against Company that is based on (a) any breach of Contractor’s representations, warranties or covenants in this Agreement and (b) any act or omission of Contractor that results in: (i) bodily injury, sickness, disease or death; (ii) damage, injury or destruction to tangible or intangible property; (iii) the violation of any law, statute, ordinance, or regulation; or (iv) any other damage, loss, cost or expense, by whomsoever suffered, resulting or claimed to result in whole or in part from Contractor’s provision of the Services. ',
  despaxoDeliveryPolicySlogan36: '9. Solicitation of Services.  Contractor agrees that, during the term of the Agreement and for a period of one (1) year thereafter, Contractor will not solicit or attempt to solicit, for the purpose of engaging in competition with the Company (whether by Contractor or any other person or entity), any of the Company’s employees or consultants.  Contractor agrees via Section 7 that confidential information will not be shared, used, disclosed or leveraged in any other manner for the solicitation of the Company’s customers and/or Merchants. ',
  despaxoDeliveryPolicySlogan37: '10. LIMITATION ON LIABILITY.  UNDER NO CIRCUMSTANCE WILL THE COMPANY OR ITS AFFILIATES BE LIABLE TO CONTRACTOR OR TO ANY THIRD PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES THAT RESULT FROM THIS AGREEMENT, EVEN IF THE COMPANY OR ITS AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  IN NO EVENT WILL THE COMPANY’S TOTAL LIABILITY TO CONTRACTOR FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT (WHETHER IN CONTRACT OR TORT, INCLUDING NEGLIGENCE, WARRANTY, OR OTHERWISE) EXCEED THE FEES PAYABLE BY THE COMPANY TO CONTRACTOR HEREUNDER. ',
  despaxoDeliveryPolicySlogan38: '11. Dispute Resolution - Arbitration.',
  despaxoDeliveryPolicySlogan39: 'This Agreement is covered by the Despaxo  Arbitration clause  located in the Terms of Use - DISPUTE RESOLUTION, and is copied here in its entirety, AND IS APPLICABLE TO ALL USERS OF THE DESPAXO PLATFORM.',
  despaxoDeliveryPolicySlogan41: '“PLEASE READ THIS “DISPUTE RESOLUTION” SECTION CAREFULLY. IT LIMITS THE WAYS YOU CAN SEEK RELIEF FROM DESPAXO AND REQUIRES YOU TO ARBITRATE DISPUTES ON AN INDIVIDUAL BASIS. AS STATED ABOVE, IN ARBITRATION, THERE IS NO JUDGE OR JURY AND THERE IS MORE CIRCUMSCRIBED DISCOVERY AND APPELLATE REVIEW THAN THERE IS IN COURT.',
  despaxoDeliveryPolicySlogan42: 'I. Informal Dispute Resolution Procedure.',
  despaxoDeliveryPolicySlogan43: 'There might be instances when a Dispute (as defined below) arises between you and Despaxo. In those instances, Despaxo is committed to working with you to reach a reasonable resolution; however, we can only do this if we know about and understand each other’s concerns. Therefore, for any Dispute that arises between you and Despaxo, both parties acknowledge and agree that they will first make a good faith effort to resolve it informally before initiating any formal dispute resolution proceeding in arbitration or otherwise. This includes first sending a written description of the Dispute to the other party. For any Dispute you initiate, you agree to send the written description of the Dispute along with the email address / username / phone number  associated with your account to the following location: https://dashboard.privateapp.io/support Re: (Dispute Resolution).  Your written description must be on an individual basis and also provide at least the following information: your name; a detailed description of the nature and basis of the Dispute, including any transaction details; and the specific relief sought and how it was calculated. Your written description must be personally signed by you. For any Dispute that Despaxo raises, we will send our written description of the Dispute to the email address / user name associated with your account.',
  despaxoDeliveryPolicySlogan44: 'You and Despaxo then agree to negotiate in good faith about the Dispute. This might include an informal telephonic dispute resolution conference between you and Despaxo if such a conference is requested by us. If such  a call takes place, it shall be individualized such that a separate conference must be held each time either party intends to commence individual arbitration; multiple individuals initiating claims cannot participate in the same informal telephonic dispute resolution conference. If either party is represented by counsel, that party\'s counsel may participate in the informal telephonic dispute resolution conference, but the party also must appear at and participate in the conference. This should lead to resolution, but if for some reason the Dispute is not resolved satisfactorily within sixty (60) days after receipt of the complete written description of the Dispute, you and Despaxo agree to the further dispute resolution provisions below.',
  despaxoDeliveryPolicySlogan45: 'To reiterate, this informal dispute resolution process is a prerequisite and condition precedent to commencing any formal dispute resolution proceeding. The parties agree that any relevant limitations period and filing fee or other deadlines will be tolled while the parties engage in this informal dispute resolution process. A court shall have the authority to enjoin the filing or prosecution of arbitrations based on a failure to comply with this Informal Dispute Resolution Procedure. A party may raise non-compliance with this Informal Dispute Resolution Procedure in court and/or in connection with the arbitration.',
  despaxoDeliveryPolicySlogan46: 'II. Mutual Arbitration Agreement.',
  despaxoDeliveryPolicySlogan47: 'You and Despaxo agree that all claims, disputes, or disagreements that may arise out of the interpretation or performance of this Agreement or payments by or to Despaxo, or that in any way relate to your use of the Platform, the Materials, the Services, and/or other content on the Platform, your relationship with Despaxo, or any other dispute with  Despaxo, (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory) (each, a “Dispute”) shall be submitted exclusively to binding arbitration. Dispute shall have the broadest possible meaning. This includes claims that arose, were asserted, or involve facts occurring before the existence of this or any prior Agreement as well as claims that may arise after the termination of this Agreement. This Mutual Arbitration Agreement is intended to be broadly interpreted.',
  despaxoDeliveryPolicySlogan48: 'Notwithstanding the foregoing, issues related to the scope, validity, and enforceability of this Arbitration Agreement are for a court to decide. Also, each party retains the right to (1) elect (at any time prior to the appointment of an arbitrator) to have any claims heard in small claims court on an individual basis for disputes and actions within the scope of such court’s jurisdiction, provided the proceeding remains in small claims court and is not removed or appealed to a court of general jurisdiction, and (2) seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party\'s copyrights, trademarks, trade secrets, patents, or other confidential or proprietary information or intellectual property rights. For clarity, this “Dispute Resolution” section does not alter, amend, or affect any of the rights or obligations of the parties to any Despaxo Delivery Partner Agreement.',
  despaxoDeliveryPolicySlogan49: 'ARBITRATION MEANS THAT AN ARBITRATOR AND NOT A JUDGE OR JURY WILL DECIDE THE DISPUTE. RIGHTS TO PREHEARING EXCHANGE OF INFORMATION AND APPEALS MAY BE LIMITED IN ARBITRATION. YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU AND DESPAXO ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY TO THE MAXIMUM EXTENT PERMITTED BY LAW.',
  despaxoDeliveryPolicySlogan50: 'III. Class Action and Collective Relief Waiver.',
  despaxoDeliveryPolicySlogan51: 'YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT ALLOWED BY LAW, EXCEPT AS SET OUT IN SECTION VII BELOW, THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE ARBITRATED OR LITIGATED ON A CLASS, JOINT, COLLECTIVE OR CONSOLIDATED BASIS OR IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC, OR AS A PRIVATE ATTORNEY GENERAL OR FOR PUBLIC INJUNCTIVE RELIEF. UNLESS BOTH YOU AND DESPAXO OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS (EXCEPT AS SET OUT IN SECTION VII BELOW), AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY CLASS, JOINT, COLLECTIVE OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY AWARD RELIEF (INCLUDING ANY DECLARATORY OR INJUNCTIVE RELIEF) ONLY IN FAVOUR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO RESOLVE AN INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT AWARD RELIEF FOR OR AGAINST ANYONE WHO IS NOT A PARTY TO THE PROCEEDING.',
  despaxoDeliveryPolicySlogan52: 'THIS CLASS ACTION AND COLLECTIVE RELIEF WAIVER IS AN ESSENTIAL PART OF THIS “DISPUTE RESOLUTION” SECTION, AND IF IT IS DEEMED INVALID OR UNENFORCEABLE WITH RESPECT TO A PARTICULAR CLAIM OR DISPUTE, NEITHER YOU NOR DESPAXO IS ENTITLED TO ARBITRATION OF SUCH CLAIM OR DISPUTE. NOTWITHSTANDING THE FOREGOING, IF A COURT DETERMINES THAT THE CLASS ACTION AND COLLECTIVE RELIEF WAIVER IS NOT ENFORCEABLE AS TO A PARTICULAR CLAIM OR REQUEST FOR RELIEF OR A REQUEST FOR PUBLIC INJUNCTIVE RELIEF AND ALL APPEALS FROM THAT DECISION HAVE BEEN EXHAUSTED (OR THE DECISION IS OTHERWISE FINAL), THEN THE PARTIES AGREE THAT THAT PARTICULAR CLAIM OR REQUEST FOR RELIEF MAY PROCEED IN COURT BUT SHALL BE STAYED PENDING ARBITRATION OF THE REMAINING CLAIMS AND REQUESTS FOR RELIEF.',
  despaxoDeliveryPolicySlogan53: 'IV. Arbitration Rules.',
  despaxoDeliveryPolicySlogan54: 'The arbitration will be administered by the  Laws of Saint Christopher (St Kitts) and Nevis Arbitration Act Revised 2002). Except as modified by this “Dispute Resolution” section, the Act will administer the arbitration in accordance with either (A) the Commercial Arbitration Rules than in effect, or (B) the Consumer Arbitration Rules then in effect. ',
  despaxoDeliveryPolicySlogan55: 'V. Arbitration Process.',
  despaxoDeliveryPolicySlogan56: 'If after sixty (60) days the Informal Dispute Resolution Procedure above is unsuccessful in resolving the parties’ dispute, a party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the Applicable Arbitration rules. The arbitration will be conducted by a single arbitrator. The arbitrator will be either a retired judge or an attorney licensed to practice law in the named jurisdiction. The parties will first attempt to agree on an arbitrator. If the parties are unable to agree upon an arbitrator within twenty-eight (28) days of receiving the list of eligible neutrals, then in accordance with the Arbitration Act, a tribunal will decide the arbitrator.',
  despaxoDeliveryPolicySlogan57: 'VI. Arbitration Location and Procedure.',
  despaxoDeliveryPolicySlogan58: 'As stated, the arbitration will be conducted in St Kitts and Nevis. If the amount in controversy does not exceed $10,000 and you do not seek injunctive or declaratory relief, then the arbitration will be conducted solely on the basis of documents you and Despaxo submit to the arbitrator, unless a party requests a hearing and the arbitrator determines that a hearing is necessary. If the amount in controversy exceeds $10,000 or seeks declaratory or injunctive relief, the right to a hearing will be determined by the Applicable Rules of Arbitration Act. Subject to the Applicable Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration. Unless otherwise prohibited by law or agreed by the parties, all arbitration proceedings and all related records will be confidential and closed to the public and any parties other than you and Despaxo, except as necessary to obtain court confirmation of the arbitration award.',
  despaxoDeliveryPolicySlogan59: 'VII. Batch Arbitration.',
  despaxoDeliveryPolicySlogan60: 'To increase the efficiency of administration and resolution of arbitrations, in the event 100 or more similar arbitration demands presented by or with the assistance or coordination of the same law firm or organization are submitted against Despaxo, the arbitration provider shall (i) administer the arbitration demands in batches of 100 demands per batch (to the extent there are fewer than 100 arbitration demands left over after the batching described above, a final batch will consist of the remaining demands); (ii) designate a single, different arbitrator for each batch (unless the parties agree otherwise); and (iii) provide for a single filing set of fees (for example, if The Arbitrator - St Kitts Nevis  is the arbitration provider, one filing fee, case management fee, and arbitrator compensation fee due per side per batch. You agree to cooperate in good faith with Despaxo and the arbitration provider to implement such a “batch approach” or other similar approach to provide for an efficient resolution of claims, including the payment of single filing and administrative fees for batches of claims. This “Batch Arbitrations” provision shall in no way be interpreted as authorizing class arbitration of any kind. Despaxo expressly reserves its right to raise unique defenses as to each claimant in connection with this process.',
  despaxoDeliveryPolicySlogan61: 'VIII. Arbitrator\'s Decision.',
  despaxoDeliveryPolicySlogan62: 'The arbitrator will render an award within the time frame specified in the applicable Rules. The arbitrator\'s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator will have the authority to award monetary damages on an individual basis and to grant, on an individual basis, any non-monetary remedy or relief available to an individual to the extent available under applicable law, the arbitral forum’s rules, and this Agreement. The arbitrator\'s award of damages and/or other relief must be consistent with section III above and also must be consistent with the terms of the "Limitation of Liability" section above as to the types and the amounts of damages or other relief for which a party may be held liable. The arbitrator’s award shall be binding only between the parties to the arbitration proceeding and shall have no preclusive effect in any other arbitration or other proceeding involving a different party.',
  despaxoDeliveryPolicySlogan63: 'Attorneys’ fees will be available to the prevailing party in the arbitration only if authorized under applicable substantive law governing the claims in the arbitration. The arbitrator shall apply the provisions of Civil Procedure 68 (UK) after the arbitration award is entered. If the arbitrator finds that either the substance of a claim or the relief sought in a Demand for Arbitration was frivolous or was brought for harassment or an improper purpose (as measured by the standards set forth under St Kitts and Nevis civil proceedings), the arbitrator may award attorneys’ fees, costs and expenses in favour of a party.',
  despaxoDeliveryPolicySlogan64: 'IX. Fees.',
  despaxoDeliveryPolicySlogan65: 'Your responsibility to pay any Arbitration filing, administrative, and/or arbitrator fees will be solely as set forth in the applicable Rules (The Arbitrator, St Kitts and Nevis). If, however, you are able to demonstrate that the costs of arbitration will be prohibitive for you as compared to the costs of litigation, Despaxo will reimburse as much of the filing, administration, and/or arbitrator fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive to you.',
  despaxoDeliveryPolicySlogan66: 'X. Changes.',
  despaxoDeliveryPolicySlogan67: 'Despaxo reserves the right to change this "Dispute Resolution" section. If Despaxo changes this "Dispute Resolution" section after the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement), you agree that your continued use of the Platform or Services after such change will be deemed acceptance of those changes. If you do not agree to such change, you may reject any such change by providing Despaxo written notice of such rejection by digital correspondence at: https://dashboard.privateapp.io/supportmail (Re: Rejection to new Terms) within 30 days of the date such change became effective. In order to be effective, the notice must include your full name and clearly indicate your intent to reject changes to this "Dispute Resolution" section. By rejecting changes, you are agreeing that you will arbitrate any dispute between you and Despaxo in accordance with the provisions of this "Dispute Resolution" section as of the date you first accepted this Agreement (or accepted any subsequent changes to this Agreement, as applicable).”',
  despaxoDeliveryPolicySlogan68: '12. Miscellaneous.',
  despaxoDeliveryPolicySlogan69: '(a) Amendments and Waivers.  Any term of this Agreement may be amended or waived only with the written consent of the Company. ',
  despaxoDeliveryPolicySlogan70: '(b) Sole Agreement.  This Agreement constitutes the sole agreement of the parties and supersedes all oral negotiations and prior writings with respect to the subject matter hereof. ',
  despaxoDeliveryPolicySlogan71: '(c) Notices.  Any notice required or permitted by this Agreement shall be in writing and shall be deemed sufficient upon receipt, when delivered via e-message to Company at https://dashboard.privateapp.io/support ',
  despaxoDeliveryPolicySlogan72: '(Re: DRIVER CORRESPONDENCE)',
  despaxoDeliveryPolicySlogan73: '72 hours after message has been sent, and the same as to the e-mail on record for Contractor.',
  despaxoDeliveryPolicySlogan74: 'BOTH PARTIES ACKNOWLEDGE THAT NOTICES RELATING TO SECTION 4 (TERM AND TERMINATION) WILL BE CONSIDERED “IN WRITING” WHEN SENT VIA EMAIL TO THE EMAIL ADDRESS PROVIDED BY THE CONTRACTOR AND USED IN COMMUNICATION BETWEEN THE COMPANY AND CONTRACTOR. CONTRACTOR HAS A RIGHT TO REQUEST ADDITIONAL WRITTEN CONFIRMATION OF THE NOTICE(S) MEETING THE STANDARDS INCLUDED IN SECTION 12 (C) WHEN EMAIL CORRESPONDENCE IS USED; HOWEVER, CONTRACTOR’S FAILURE TO REVIEW THE NOTICE(S) SENT VIA EMAIL WILL NOT HAVE ANY IMPACT ON THE COMMUNICATIONS INCLUDED IN THE EMAIL NOTICE AS LONG AS THE COMPANY IS ABLE TO PROVIDE VERIFICATION THAT THE EMAIL WAS SENT TO THE EMAIL ADDRESS PROVIDED BY THE CONTRACTOR.  ',
  despaxoDeliveryPolicySlogan75: '(d) Choice of Law.  Except as provided in Section 11, the validity, interpretation, construction and performance of this Agreement shall be governed by the laws of Federation of St Christopher (St Kitts) and Nevis, without giving effect to the principles of conflict of laws. ',
  despaxoDeliveryPolicySlogan76: '(e) Severability.  If one of more provisions of the Agreement are held to be unenforceable under applicable law, the parties agree to renegotiate such provision in good faith.  In the even that the parties cannot reach a mutually agreeable and enforceable replacement for such provision, then (i) such provision shall be excluded from this Agreement, (ii) the balance of the Agreement shall be interpreted as if such provision were so excluded and (iii) the balance of the Agreement shall be enforceable in accordance with its terms. \n',
  despaxoDeliveryPolicySlogan77: '(f) Counterparts.  This Agreement may be executed digitally where each document shall be deemed an original, but all of which together will constitute one and the same instrument.',
  despaxoDeliveryPolicySlogan78: '(g) Advice of Counsel.  EACH PARTY ACKNOWLEDGES THAT, IN EXECUTING THIS AGREEMENT,SUCH PARTY HAS HAD THE OPPORTUNITY TO SEEK THE ADVICE OF INDEPENDENT LEGAL COUNSEL, AND HAS READ AND UNDERSTOOD ALL OF THE TERMS AND PROVISIONS OF THIS AGREEMENT.  THIS AGREEMENT SHALL NOT BE CONSTRUED AGAINST ANY PARTY BY REASON OF THE DRAFTING OR PREPARATION HEREOF. ',
  despaxoDeliveryPolicySlogan79: 'AGREEMENT ACCEPTANCE STATEMENTS ',
  despaxoDeliveryPolicySlogan80: 'A. By clicking the agreeing below, I am stating that I fully understand that the Agreement between the Company and I is one of an Independent Contractor / Professional Services Provider and not of an employee. ',
  despaxoDeliveryPolicySlogan81: 'B. By agreeing below, I am stating that I have all necessary equipment, licenses and insurance to meet standards of this agreement.  ',
  despaxoDeliveryPolicySlogan82: 'C. By agreeing below, I am stating that I understand and consent to Mutual Arbitration, waiving any right to trial by jury and participation in class, collective, or representative action.',
  despaxoDeliveryPolicySlogan83: 'D. I am legally competent to enter into this Agreement and voluntarily agree to be bound by the terms of this Agreement.',
  despaxoDeliveryPolicySlogan84: 'LAST UPDATED : 03 APRIL 2023',
  back: 'Back',
  registration: "Registration",
  agree: "Agree",
  skip: "Skip",
  backgroundCheck: 'Background Check',
  provideTripsWithMyCar: "Provide trips with my car",
  someoneToDriveMyVehicle: "I want someone to drive my Vehicle",
  driveButDontHaveCar: "I want to drive but don't have a car",
  deliverOnMoto: "I want to deliver on Moto",
  deliverOnBicycle: "I want to deliver by Bicycle",
  deliverSmallFreight: "I want to deliver small freight delivery",
  deliverLargeFreight: "I want to provide large freight delivery",
  offerAllTerrainRides: "I want to offer All terrain 4x4 rides",
  offerGroupsSmallShuttleRides: "I want to offer groups small shuttle rides",
  offerRidesByMoto: "I want to offer rides by moto",
  fieldDriverTypeLabel: "Driver type",
  fieldConfirmCodeLabel: "Code",
  confirmEmailAddress: "Confirm email",
  confirmEmailAddressHint: "We have sent you a confirmation email. Please add the six digit code we sent you.",
  confirm: "Confirm",
  serverError_otp_unconfirmed: "Wrong otp code",
  serverError_exists: "This field already exist",
  fieldIdNumberLabel: "Id number",
  create: "Create",
  registrationDesc1: "Thanks for your interest in Despaxo Driver Services",
  registrationDesc2: "Please complete the Registration Form. Once approved, you will be notified through Hola Chat services.",
  registrationDesc3: "You should know, during Registration you’ll need to complete most parts.",
  registrationDesc4: "Your profile may be edited after the Registration process by downloading Hola Chat or via login through the web dashboard.",
  registrationDesc5: "Keep in mind a Despaxo Driver is an active position and demands not only a love of customers, but a desire to earn through commitment: Always trying to be the best service provider.",
  registrationDesc6: "And, for the really good news:",
  registrationDesc7: "The Despaxo platform charges “Zero” commissions to our drivers!",
  registrationDesc8: "And, Despaxo offers really cool ways to earn more money. Just Ask your Sponsor!",
  registrationDesc9: "But, one thing’s for sure: Stop \"paying out” your hard earned commissions to other delivery applications for too much work and not enough pay.",
  registrationDesc10: "Join the Despaxo Delivery Revolution and keep more of your money in your own pocket.",
  registrationDesc11: "Register Today and be an Active part of our Exciting Team.",
  getStarted: "Get Started",
  pendingApproval: "Pending Approval",
  notRegisteredUserDriverHint: "You're not registered as a despaxo driver? Start the process now.",
  acceptAgreement: "Accept agreement",
  chooseOptions: "-choose an option-",
  fieldPhoneLabel: "Phone",
  enterPhoneNumber: "Enter phone number",
  iHaveHolaProfile: "I already have Hola account",
  confirmOtp: "Confirm Otp",
  validationError_phone_exists: "This phone already use",
  validationError_phone_invalid: "Invalid phone number",
  validationError_phone_required: "Fill out this field",
  declinedProfile: "Unfortunately, your profile does not meet the requirements for obtaining driver status on our platform at the moment. Thank you for your understanding.",
  pendingProfile: "Your profile is currently under review for driver status. We appreciate your patience and will reach out once the review is complete.",
  registrationTitle: "Despaxo Driver Services",
  settings: "Settings",
  profile: "Profile",
  license: "License",
  licensePhotoFront: "License Photo Front",
  licensePhotoBack: "License Photo Back",
  licenseIdConfirmation: "License ID confirmation",
  countryDrivingFor: "Country driving for",
  update: "Update",
  orders: "Orders",
  wallet: "Wallet",
  menu: "Menu",
  verifyAccount: "Verify account",
  satisfactionRating: "Satisfaction Rating",
  yourBalance: "Your Balance",
  successDeliveries: "Successful Deliveries",
  history: "History",
  home: "Home"
}
