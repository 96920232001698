import Vue from 'vue'
import VueI18nManager from 'vue-i18n-manager'
import store from '@/store'
import router from '../router'

import locales from '@/translations'

Vue.use(VueI18nManager, {
  store,
  router,
  config: {
    defaultCode: 'en-US',
    languages: [
      {
        name: 'English',
        title: 'Eng.',
        key: 'gb',
        code: 'en-US',
        urlPrefix: 'en',
        translationKey: 'en',
        requestKey: 'en'
      },
      {
        name: 'Espaniol',
        title: 'Esp.',
        key: 'es',
        code: 'es-Es',
        urlPrefix: 'es',
        translationKey: 'es',
        requestKey: 'es'
      },
      {
        name: 'Русский',
        title: 'Rus.',
        key: 'ru',
        code: 'ru-Rus',
        urlPrefix: 'ru',
        translationKey: 'ru',
        requestKey: 'ru'
      },
      {
        name: 'German',
        title: 'Ger.',
        key: 'de',
        code: 'de',
        urlPrefix: 'de',
        translationKey: 'de',
        requestKey: 'de'
      },
      {
        name: 'Japanese',
        title: 'jp.',
        key: 'jp',
        code: 'jp',
        urlPrefix: 'jp',
        translationKey: 'jp',
        requestKey: 'ja'
      },
      {
        name: 'Chinese',
        title: 'Zh.',
        key: 'zh',
        code: 'zh-CN',
        urlPrefix: 'zh',
        translationKey: 'zh',
        requestKey: 'zh'
      },
      {
        name: 'French',
        title: 'Fr.',
        key: 'fr',
        code: 'fr-FR',
        urlPrefix: 'fr',
        translationKey: 'fr',
        requestKey: 'fr'
      },
      {
        name: 'Portuguese',
        title: 'Pt.',
        key: 'pt',
        code: 'pt-PT',
        urlPrefix: 'pt',
        translationKey: 'pt',
        requestKey: 'pt'
      },
      {
        name: 'Hindi',
        title: 'Hi.',
        key: 'hi',
        code: 'hi-Hi',
        urlPrefix: 'hi',
        translationKey: 'hi',
        requestKey: 'hi'
      }
    ],
    translations: locales
  }
})
