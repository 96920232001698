import api from '@/api/info'

const state = {
  countries: null,
  loading: false
}

const getters = {
  countries: state => state.countries,
  loading: state => state.loading,
}

const actions = {
  async fetchCountries ({ commit }) {
    try {
      const result = await api.fetchCountries()
      commit('setCountries', result)
      return result
    } catch (e) {
      throw e.response
    }
  },
  async createContactInfo ({ commit }, payload) {
    try {
      const result = await api.createContactInfo(payload)
      return result
    } catch (e) {
      throw e.response
    }
  }
}

const mutations = {
  setCountries (state, list) {
    state.countries = list
  },
  setLoading (state, status) {
    state.loading = status
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
