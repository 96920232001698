import {snakeCase} from 'lodash'
export function base64ToFile (dataURI, filename) {
  if(!dataURI) return ''
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeString })
  return new File([blob], `${filename}${mimeString.replace('image/', '.')}`, { type: mimeString })
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
})

export function _base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64.split(',')[1]);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer
}

export function _arrayBufferToBase64( buffer ) {
  let binary = '';
  const bytes = new Uint8Array( buffer );
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode( bytes[ i ] );
  }
  return window.btoa( binary );
}

export function toCamelCaseRequestPayload (data) {
  const payload = {}
  Object.entries(data).forEach(e => {
    const [key, value] = e
    payload[snakeCase(key)] = value
  })
  return payload
}
