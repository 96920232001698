<template>
  <div class="wrapper"
       :class="{ 'vendor': $route.fullPath.includes('/vendor') }"
       :style="{
            '--primary': theme.primary,
            '--secondary': theme.secondary,
            '--third': theme.third,
            '--lightBackground': theme.lightBackground,
            '--darkBackground': theme.darkBackground,
            '--lightTextColor': theme.lightTextColor,
            '--darkTextColor': theme.darkTextColor,
          }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'TheWrapper',
  watch: {
    '$route.fullPath'(){
      if(this.$route.fullPath.includes('/vendor')){
        this.$vuetify.theme.themes.light.primary = '#483f8c'
        this.$vuetify.theme.themes.light.secondary = '#766ac8'
        this.$vuetify.theme.themes.light.third = '#9d98c1'
      }else{
        this.$vuetify.theme.themes.light.primary = '#074605'
        this.$vuetify.theme.themes.light.secondary = '#00b764'
        this.$vuetify.theme.themes.light.third = '#88A7AD'
      }
    }
  },
  computed: {
    theme () {
      return this.$vuetify.theme.themes.light
    }
  }
}
</script>
