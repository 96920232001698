  import Vue from 'vue'

export default Vue.mixin({
  data() {
    return {
      nameRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => (v && v.length <= 40) || 'Full name must be less than 40 characters'
      ],
      emailRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => /.+@.+\..+/.test(v) || this.$t('validationEmail')
      ],
      required: [
        (v) => !!v  || this.$t('validationRequired')
      ],
      requiredSelect: [
        (v) => !!v.toString() || this.$t('validationRequired')
      ],
      codeRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => (v && v.length <= 6) || 'Code must be less than 6 characters',
        (v) => (v && v.length >= 6) || 'Code must be no less than 6 characters'
      ],
      phoneRules: [
        v => !!v || this.$t('validationRequired'),
        v => /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(v) || 'Enter valid phone number',
        v => v && v.length >= 12 || 'Phone must be no less than 12 characters'
      ],
      onlyNumberRules: [
        (v) => !!v || this.$t('validationRequired'),
        v => /^[a-zA-Z-z0-9]+(\[a-zA-Z-z0-9])*$/.test(v) || 'Enter only numbers'
      ],
      yearVehicleRules: [
        // v => v.length >= 1 && v.length === 4 || 'The year of the vehicle must have 4 characters.' // not finished, it should be redone
      ],
      withoutEmojiRules: [
        v => !/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu.test(v) || 'Enter valid name',
      ],
      latinLettersRules: [
        v => !!v || this.$t('validationRequired'),
        v => /^[a-zA-Z-z0-9,]+(\[a-zA-Z-z0-9, ])*$/.test(v) || 'Enter only latin letters'
      ],
      passwordRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => (v && v.length <= 50) || 'Password must be less than 50 characters',
        (v) => (v && v.length >= 8) || 'Password must be no less than 8 characters'
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t('validationRequired'),
        (v) => (v === this.payload.newPassword) || this.$t('validationSameAs')
      ],
      urlRules: [
        (v) => !!v || this.$t('validationRequired'),
        v => /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi.test(v) || 'Invalid url'
      ]
    }
  },
})
