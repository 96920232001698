export const saveTokensToStorage =
  ({accessToken, refreshToken=null}) => {
    const tokens = {accessToken, refreshToken};
    for (const [key, value] of Object.entries(tokens)) {
      const jsonValue = JSON.stringify(value);

      localStorage.setItem(key, jsonValue);
    }
  };
export const getAccessToken = () => {
  const token = localStorage.getItem('accessToken');
  return JSON.parse(token);
};
export const getRefreshToken = () => {
  const token = localStorage.getItem('refreshToken');
  return JSON.parse(token);
};

export const clearTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
};
