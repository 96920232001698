import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang(.{2})?',
      name: 'auth',
      component:  () => import('@/views/SignIn.vue')
    },
    {
      path: '/:lang?/registration',
      name: 'registration',
      component: () => import('@/views/Registration.vue')
    },
    {
      path: '/:lang?/admin',
      name: 'main',
      component: () => import('@/views/Main.vue'),
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@/views/Dashboard.vue')
        },
        {
          path: 'settings',
          name: 'settings',
          component: () => import('@/views/Settings.vue')
        },
        {
          path: 'active-orders',
          name: 'active-orders',
          component: () => import('@/views/Orders.vue')
        },
        {
          path: 'wallet',
          name: 'wallet',
          component: () => import('@/views/Wallet.vue')
        },
        {
          path: 'history',
          name: 'history',
          component: () => import('@/views/History.vue')
        },
      ]
    },
    {
      path: '/*',
      redirect: {
        name: 'auth'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }
  }
})

export default router
