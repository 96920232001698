<template>
  <div class="header" id="header">
    <div class="header-wrap">
      <div class="header-logo">
        <div class="header-logo__wrap">
          <img src="../../assets/img/despaxo-logo-white.png" alt="">
        </div>
      </div>
      <div class="header-right">
        <div class="header-language" :class="{ 'has-menu': $route.name === 'auth' }">
          <tooltip-layout :maxWidth="160" position="left">
            <template slot="icon">
              <img :src="`/img/locales/${currentLanguage.urlPrefix}.png`" class="flag">
            </template>
            <ul>
              <li v-for="item in languages" :key="item.id" class="column">
                <a @click.prevent="changeLanguage(item)" :title="item.title" href="#"
                   v-if="item.urlPrefix !== currentLanguage.urlPrefix">
              <span class="language-item">
                <img :alt="item.alt"
                     :src="`/img/locales/${item.urlPrefix}.png`"
                     class="flag"/>
                <span class="language__text">{{ item.name }}</span>
              </span>
                </a>
              </li>
            </ul>
          </tooltip-layout>
        </div>
        <div class="header-menu" :class="$route.name">
          <tooltip-layout :maxWidth="300" position="left">
            <template #icon>
              <burger/>
            </template>
            <template #default>
              <div v-if="profile && $route.name !== 'registration'" class="header-user pl-2 pr-2">
                <div class="header-user__left mr-2">
                  <object v-if="profile.avatarLink"
                          :data="profile.avatarLink"
                          style="max-width: 60px;"
                          type="image/jpg">
                  </object>
                </div>
                <div class="header-user__right">
                  <div class="header-user__name" style="font-size: 18px; font-weight: 600;" v-show="profile.firstName">
                    {{ profile.firstName }} {{ profile.lastName || '' }}
                  </div>
                  <div class="header-user__verify" v-if="driver?.verificationStatus === $options.verificationStatuses.approve">
                    <div class="d-flex justify-start align-center">
                      <v-icon color="secondary">verified</v-icon>
                      <span class="ml-1 font-weight-medium" style="font-size: 14px;">
                    {{ $t('verifyAccount') }}
                  </span>
                    </div>
                  </div>
                </div>
              </div>
              <v-list nav>
                <v-list-item-group
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in menuList"
                    :key="i"
                    v-show="item.show"
                    :class="{ 'v-list-item--active': item.to === activeRouteName }"
                    @click="navigateTo(item)"
                  >
                    <v-list-item-icon class="mr-4 ml-1">
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </tooltip-layout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Burger from "@/components/atoms/Burger.vue";
import TooltipLayout from '@/components/atoms/TooltipLayout.vue'

import {mapGetters} from 'vuex'
import {menuList} from '@/helpers/variables'
import {verificationStatuses} from "@/helpers/variables/statuses";
import {clearTokens} from '@/helpers/tokens'

export default {
  name: 'AppHeader',
  verificationStatuses,
  data() {
    return {
      activeRouteName: ''
    }
  },
  components: {
    Burger,
    TooltipLayout
  },
  computed: {
    menuList,
    ...mapGetters({
      profile: 'auth/myProfile',
      driver: 'driver/currentItem'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ])
  },
  mounted() {
    this.activeRouteName = this.$route.name
  },
  methods: {
    changeLanguage(language) {
      this.$setLanguage(language.code)
      setTimeout(() => {
        window.location.reload()
      }, 800)
    },
    handleLogout() {
      clearTokens()
      this.$router.push({name: "auth"})
      setTimeout(() => {
        window.location.reload()
      }, 200)
    },
    navigateTo(item) {
      if (item.to === 'logout') {
        this.handleLogout()
        return
      }
      this.$router.push({name: item.to})
      this.activeRouteName = item.to

    },
  }
}
</script>

<style lang="scss">
.header {
  background: #00b764;
  transition: background $trans;
  z-index: 10;
  height: 145px;
  position: relative;
  @include respondTo(800px) {
    height: 60px;
  }
  &-user{
    display: none;
    border-bottom: 1px solid $silver-2;
    @include respondTo(1100px){
      display: flex;
    }
  }
  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respondTo(600px) {
      justify-content: flex-start;
      padding-left: 12px;
    }
  }

  &-logo {
    &__wrap {
      display: block;
      width: 300px;
      cursor: pointer;
      @include respondTo(1200px) {
        width: 200px;
      }
      @include respondTo(800px) {
        width: 120px;
      }

      img {
        width: 100%;
        height: 100%;
        transition: transform $trans;
      }
    }
  }

  &-language {
    position: relative;
    z-index: 3;
  }
  &-right{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include respondTo(960px){
      right: 15px;
    }
  }

  &-menu {
    position: relative;
    z-index: 3;
    display: none;
    padding-bottom: 0!important;
    margin-left: 15px;
    @include respondTo(960px) {
      display: block;
    }
    &.auth{
      display: none;
    }
    &.registration{
      display: block;
    }

    &__item {
      a.router-link-exact-active {
        background: rgba(0, 0, 0, 0.05);
        pointer-events: none;
      }
    }

    .tooltip-layout li a {
      font-size: 16px;
    }

  }

  &-lang {
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &__name {
      margin-left: 10px;
    }
  }
}
</style>
