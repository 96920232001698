<template>
  <modal
    v-bind="$attrs"
    v-on="$listeners"
    :class="$style.wrapper"
    :name="name"
    width="450"
    height="auto"
    adaptive
    scrollable
    transition="modal-fade"
  >
    <h1 v-if="title" :class="$style.title">{{ title }}</h1>
    <span @click="$modal.hide(name)" :class="$style.close">
      <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.171 241.171"><path
          d="M138.138 120.754l99.118-98.576a11.931 11.931 0 000-17.011c-4.74-4.704-12.439-4.704-17.179 0l-99.033 98.492-99.949-99.96c-4.74-4.752-12.439-4.752-17.179 0-4.74 4.764-4.74 12.475 0 17.227l99.876 99.888L3.555 220.497c-4.74 4.704-4.74 12.319 0 17.011 4.74 4.704 12.439 4.704 17.179 0l100.152-99.599 99.551 99.563c4.74 4.752 12.439 4.752 17.179 0 4.74-4.764 4.74-12.475 0-17.227l-99.478-99.491z"></path></svg>
    </span>

    <div :class="$style.body" class="body">
      <slot name="default" />
    </div>

    <div v-if="$slots.footer" :class="$style.footer">
      <slot name="footer" />
    </div>
  </modal>
</template>

<script>
export default {
  name: 'secondary-modal',
  props: {
    name: String,
    title: String
  }
}
</script>

<style lang="scss" module>
.wrapper :global(.v--modal) {
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  border-radius: 0;
  padding: 36px 32px;
}
.title {
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.close {
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 18px;
  right: 18px;
  svg{
    width: 100%;
    height: 100%;
    transform-origin: center;
    transition: transform 1s ease;
    path{
      transition: fill $trans;
    }
    &:hover {
      transform: rotate(180deg);
      path {
        fill: #00b764;
      }
    }
  }
}
.body {
  text-align: center;
  color: #3b3b3b;
  font-size: 14px;
  line-height: 1.3;
  div {
    word-wrap: break-word;
  }
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
<style lang="scss">
.vm--modal {
  background: $main-bg-light;
  box-shadow: 0 3px 11px 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  border-radius: 0;
  padding: 36px 32px;
}
</style>
