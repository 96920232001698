export default {
  // footer
  termOfService: 'Durée des services',
  privacyPolicy: 'Politique de confidentialité',
  xCommunications: 'xCommunications',
  xCommunicationsInvestorRelations: 'Relations avec les investisseurs',
  xCommunicationsMediaRequest: 'Demande média',
  xCommunicationsOurPositionPrivacy: 'Notre position sur la confidentialité',
  xCommunicationsKnowledgeBase: 'Base de connaissances',
  xCommunicationsOurPosition: 'Notre position sur la cyberintimidation',
  premiumTools: 'Outils premium',
  premiumToolsSayHolaLiveChatPlugin: 'Plugin de chat en direct sayHola',
  premiumToolsSayHolaBlogPlugin: 'Plugin de blog sayHola',
  premiumToolsTogetherSayHola: 'ensemble par sayHola',
  premiumToolsLiveLeagueApp: 'Application de la ligue en direct',
  programs: 'Programmes',
  programsAdvertiseHola: 'Faites de la publicité avec Hola',
  programsFreelancerProgram: 'Programme de pigistes',
  programsAffiliateRewardsProgram: 'Programme de récompenses d\'affiliation',
  terms: 'termes',
  termsAdsPolicy: 'Politique relative aux annonces',
  termsPrivacyPolicy: 'Politique de confidentialité',
  termsCommunityStandards: 'Normes communautaires',
  termsTermsUse: 'Conditions d\'utilisation',
  termsLoyaltyRewardsTerms: 'Conditions des récompenses de fidélité',
  // Home Page
  homeSlider: {
    haveAccount: 'Vous avez déjà un compte?',
    changeNumber: 'Besoin de changer de numéro ?',
    getStartedButton: 'Commencez maintenant!',
    sliderPrevButton: 'Dos',
    sliderNextButton: 'Continuer à lire',
    slogan1: 'La plate-forme technologique Hola Cross Platform Chatbot Bridge aide à convertir vos utilisateurs en clients à long terme. Avec Chatbot Bridge, vous pouvez générer des clients à partir de n\'importe quelle application de chat (WhatsApp, Telegram, etc.) et les gérer tous sous un seul tableau de bord.',
    slogan2: 'Nos solutions technologiques incluent des «modèles faciles à créer» pour tous les types de services, des «commandes alimentaires» au «support client» en utilisant uniquement les fonctionnalités que vous sélectionnez et utilisez.',
    slogan3: 'Vous pouvez créer vos modèles à l\'aide de chatbots basés sur des boutons pour cartographier les conversations des clients. En anticipant les types de questions que les clients sont susceptibles de poser, le chatbot est programmé pour répondre de manière appropriée, rendant ainsi l\'expérience client conviviale et authentique.',
    slogan4: 'Regardez notre démo ou commencez dès aujourd\'hui en vous connectant et en devenant un client Premium',
    needMoreInfo: 'Besoin de plus d\'informations ?',
    learnMore: 'Apprendre encore plus'
  },
  signIn: 'S\'identifier',
  recovery: 'Récupération',
  otp_unconfirmed: 'Otp non confirmé, réessayez',
  otpDescription: 'Un code SMS vous a été envoyé. Veuillez entrer le code ci-dessous.',
  otpEnterYourOtp: 'Entrez votre code OTP',
  otpEnterYourPin: 'Entrez votre code PIN',
  verify_otp: 'Confirmer',
  otpDontReceiveCode: "Vous n'avez pas reçu le code?",
  otpRequestSMSAgain: 'Demander à nouveau un SMS',
  otpRequestNewCode: 'Demander un nouveau code dans',
  copyright: 'xCommunications. Tous les droits sont réservés',
  enterPhoneNumber: 'Veuillez choisir votre pays et entrer votre numéro de téléphone complet.',
  login: 'Connexion',
  validationSelectFile: 'Sélectionnez et téléchargez une photo',
  validationSameAs: 'Les mots de passe ne correspondent pas',
  validationMaxLengthField: 'Nombre maximal de caractères de champ - :count',
  validationMinLengthField: 'Nombre minimum de caractères de champ - :count',
  validationRequired: 'Remplissez ce champ',
  validationEmail: 'Entrer un email valide',
  validationUrl: 'Entrez une URL valide',
  needChangePhone: 'Besoin de changer de numéro ?',
  needMoreInfo: 'Besoin de plus d\'informations ?',
  learnMore: 'Apprendre encore plus',
  startUsingHola: 'Commencez à utiliser sayHola pour afficher <br> l\'espace social',
  startUsingHolaFollow: 'Commencez à utiliser sayHola pour suivre',
  socialSpace: 'espace social',
  socialSpaceInvite: 'Invitation à l\'espace social',
  socialSpaceInviteMessage: 'un espace privé sûr et sécurisé pour les entreprises, les célébrités, les blogueurs, les influenceurs, la communauté et les individus sans commentaires ni fausses nouvelles',
  enterPhone: 'Entrer le téléphone',
  payNow: 'Payez maintenant',
  formCardName: 'Nom du titulaire',
  formCardNumber: 'Numéro de carte',
  formExpDate: "Date d'expiration",
  formCvv: 'CVV',
  errorEmptyField: 'Ce champ est obligatoire',
  paymentBtnPay: 'Payez maintenant',
  errorTitle: 'Erreur',
  error: "Désolé, quelque chose s'est mal passé",
  errorEmailInvalid: 'entrez une adresse e-mail valide',
  errorPhoneInvalid: 'Numéro de téléphone invalide',
  errorStripe: 'Veuillez vous assurer que vous remplissez tous les champs correctement',
  offer: 'Offrir',
  enterCode: 'Entrez le code',
  enterPin: 'Entrez le code PIN',
  enterCardholderName: 'Entrez le nom du titulaire de la carte',
  logout: 'Se déconnecter',
  confirmation: 'Confirmation',
  thanksJoining: 'Merci de nous rejoindre',
  successText: 'Visitez la FAQ et apprenez à utiliser les nombreuses fonctionnalités spéciales. Contactez l\'assistance pour toute question supplémentaire. Qu\'est-ce que tu aimerais faire?',
  downLoadApp: 'Télécharger l\'application',
  returnToHola: 'Retour à Hola',
  addSponsor: 'Ajouter un parrain',
  videoAndFAQ: 'Vidéos et FAQ',
  pin_unconfirmed: 'Entrez le bon code!',
  selectPackage: 'Sélectionnez le forfait',
  offerExpired: 'Offre spéciale expirée !',
  jobBrief: 'Fiche de poste',
  responsibilities: 'Responsabilités',
  requirements: 'Conditions',
  currentJobPositions: 'Offres d\'emploi actuelles',
  sendCampaign: 'Envoyer la campagne',
  footerPrivateAppJobsPage: 'Opportunités de travail',
  enterLatinLetter: 'Ce champ ne peut contenir que des caractères alphanumériques, tiret et trait de soulignement [a-z0-9-_]',
  nicknameExist: 'Pseudo déjà existant!',
  successCreateProfile: 'Profil créé avec succès!',
  downloadApp: 'télécharger l\'application',
  faqAndVideo: 'FAQ et vidéo',
  congratulations: 'Toutes nos félicitations',
  hosSubmitMessage: 'Comment soumettre votre candidature :',
  profilePicture: 'Image de profil',
  profilePictureMessage: 'Ajoutez votre photo de profil Social Space, nous voulons savoir avec qui nous discutons.',
  chatProfilePictureMessage: 'Ajoutez votre photo de profil, nous voulons savoir avec qui nous discutons.',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  nickname: 'Surnom',
  location: 'Lieu (ville, pays)',
  btnNext: 'Prochain',
  btnFinish: 'Finir',
  email: 'E-mail',
  emailMessage: 'Votre e-mail sera utilisé uniquement pour la récupération. En entrant votre email, vous acceptez la politique de confidentialité et les termes et conditions de sayHola. Des liens seront envoyés à votre adresse e-mail pour la récupération.',
  changesSaved: 'Changements sauvegardés!',
  emptyProfilePictureField: 'Veuillez télécharger votre photo de profil',
  register: 'S\'inscrire',
  resendOtpMessage: 'Un autre SMS a été envoyé.',
  loginByQRCodeMessage: 'Code QR pour la connexion via smartphone',
  authOption: 'Veuillez sélectionner une option pour vous connecter.',
  newRegistration: 'nouvelle inscription',
  registrationOption: 'L\'inscription nécessite un e-mail et un nom d\'utilisateur pour utiliser les services. Une fois connecté, vous avez le choix de vous synchroniser avec d\'autres applications de messagerie en utilisant votre numéro de téléphone',
  loginWithApp: 'se connecter avec l\'application',
  loginWithAppOption: 'Ouvrez Hola sur votre téléphone, accédez au menu situé dans le coin supérieur droit de l\'application. Cliquez sur l\'icône du code QR. Pointez votre téléphone vers cet écran pour confirmer la connexion.',
  recoveryOption: 'récupération',
  recoveryOptionText: 'Pour la récupération Entrez votre e-mail enregistré pour vous connecter en utilisant Hot-link. En savoir plus en visitant la section FAQ',
  enterEmailRecovery: 'Entrez votre e-mail enregistré pour récupérer:',
  startUsingSayHola: 'Commencer à utiliser sayHola',
  formEmail: 'Votre e-mail',
  notFoundUserLabel: 'Utilisateur avec cet e-mail introuvable',
  successSendRecoveryEmailMessage: 'Vérifiez votre messagerie et suivez les instructions !',
  chatBots: 'ChatBots',
  BdtlAgencyProgram: 'Programme Agence BDTL',
  agencyTerms: "Conditions d'utilisation de l'agence",
  holaExtensions: 'Hola Extension',
  holaBlog: 'Hola Blog',
  authSlogan1: 'Nous recrutons actuellement des chauffeurs-livreurs pour la nourriture et les produits en Amérique latine',
  authSlogan2: 'Nous payons les tarifs de voyage les plus élevés : 2 $ par voyage plus un supplément par KM',
  authSlogan3: 'Despaxo ne prend aucune commission aux chauffeurs',
  authSlogan4: 'Soyez votre propre patron - Soyez payé très rapidement',
  authSlogan5: 'Inscrivez-vous aujourd\'hui!',
  loginByEmail: 'Connectez-vous par e-mail',
  registerByEmail: 'Inscrivez-vous par e-mail',
  registerToday: 'Inscrivez-vous aujourd\'hui.'
}
