import api from '@/api/countries'

const state = {
  countries: []
}

const getters = {
  countries: state => state.countries
}

const actions = {
  async getCountries ({ commit }) {
    const response = await api.getCountries()
    commit('setCountries', response.data.data)
  },
}

const mutations = {
  setCountries (state, data) {
    state.countries = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
