import {base64ToFile} from '@/helpers/file'
import axios from "axios";
import {getAccessToken} from "@/helpers/tokens";

const BASE_URL = process.env.VUE_APP_MEDIA_URL

export default {
  async upload(fileBase64, onUploadProgress) {
    const file = base64ToFile(fileBase64)
    const formData = new FormData()
    formData.append('file', file)
    const {data: { data: {img_link}}} = await axios.post(`${BASE_URL}/upload`, formData, {
      onUploadProgress,
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    })
    return img_link
  }
}
