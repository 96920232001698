import api from '@/api'
import {saveTokensToStorage} from '@/helpers/tokens'

const state = {
  updatePhoneErrors: null,
  updatePhoneLoading: false,
  authByCodeResponse: null,
  myProfile: null,
  myProfileLoading: null,
  authByCodeLoading: false
}

const getters = {
  updatePhoneErrors: state => state.updatePhoneErrors,
  updatePhoneLoading: state => state.updatePhoneLoading,
  authByCodeResponse: state => state.authByCodeResponse,
  myProfileLoading: state => state.myProfileLoading,
  myProfile: state => state.myProfile,
  authByCodeLoading: state => state.authByCodeLoading
}

const actions = {
  async authByCode ({ commit }, payload) {
    try {
      commit('changeAuthByCodeLoading', true)
      const response = await api.auth.authByCode(payload)
      return response.data.data
    } catch (e) {
      throw e
    } finally {
      commit('changeAuthByCodeLoading', false)
    }
  },
  async sendQRCode ({ commit }, payload) {
    try {
      await api.auth.sendQRCode(payload)
    } catch (e) {
      throw e
    }
  },
  async getProfile ({ commit }) {
    try {
      const result = await api.auth.getProfile()
      commit('setMyProfile', result.data.data)
    } catch (e) {
      throw e.response
    }
  },
  async confirmEmailOtp ({ commit }, payload) {
    try {
      const result = await api.auth.confirmEmailOtp(payload)
      saveTokensToStorage(result.token)
      commit('setMyProfile', result)
      return result
    } catch (e) {
      throw e.response.data
    }
  },
  async updateProfile ({ commit }, payload) {
    try {
      commit('setMyProfileLoading', true)
      const result = await api.auth.updateProfile(payload)
      commit('setMyProfile', result)
      return result
    } catch (e) {
      throw e.response.data
    }finally {
      commit('setMyProfileLoading', false)
    }
  },
  async signIn ({ state }, data) {
    try {
      await api.auth.signIn(data)
    } catch (e) {
      throw e
    }
  },
  async updatePhone ({ state, commit }, data) {
    try {
      commit('setPhoneErrors', null)
      commit('setPhoneLoading', true)
      const response = await api.auth.updatePhone(data)
      return response
    } catch (e) {
      commit('setPhoneErrors', e.response)
      throw e.response
    }finally {
      commit('setPhoneLoading', false)
    }
  }
}

const mutations = {
  changeAuthByCodeLoading (state, status) {
    state.authByCodeLoading = status
  },
  setPhoneErrors (state, errors) {
    state.updatePhoneErrors = errors
  },
  setPhoneLoading (state, status) {
    state.updatePhoneLoading = status
  },
  setMyProfileLoading (state, status) {
    state.myProfileLoading = status
  },
  setMyProfile (state, data) {
    state.myProfile = data
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
